import {Table, Button, message, Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {useState} from "react";
import dayjs from "dayjs";
import { business } from '@/api';

function SignDrawer (props) {
    const { signs, assigned_tutor_id, getData, form } = props;
    const [ expandRows, setExpandRows] = useState([]);

    const assigned_sign = signs.find(i => i.tutor.id === assigned_tutor_id);
    const assigned_tutor = assigned_sign?.tutor;

    const columns = [
        { key: 'name', dataIndex: 'tutor', title: '教师姓名', width: 80, render: value => value.name},
        { key: 'advantage', dataIndex: 'advantage', title: '教学优势', width: 120, ellipsis: true,},
        { key: 'school', dataIndex: 'tutor', title: '就读院校', width: 150, render: value => getSchool(value)},
        { key: 'time_negotiation', dataIndex: 'time_negotiation', title: '协商时间', width: 120, ellipsis: true,},
        { key: 'price', dataIndex: 'price', title: '教学费用', width: 80, render: value => `${value} 元/时`},
        { key: 'operation', dataIndex: 'grade', title: '操作', width: 60, render: (value, row) => (
            <>
                {
                    !assigned_tutor_id &&
                    <Popconfirm
                        title="确认指派该老师吗"
                        onConfirm={(e) => handleAssign(row, e)}
                        onCancel={e => {e.stopPropagation()}}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button onClick={e => {e.stopPropagation()}}>指派</Button>
                    </Popconfirm>
                }
            </>
            )
        },
    ]

    function getSchool (row) {
        const schools = row.schools.sort((a, b) => dayjs(a.end_time).isBefore(dayjs(b.end_time)) ? 1 : -1);
        if (schools.length === 0) {
            return '';
        }
        const school = schools[0];
        return `${school.name} ${school.degree} ${school.major}`;
    }

    function handleClick (row) {
        const url = `${location.origin}/business/tutor/${row.tutor.id}`;
        window.open(url, '_blank');
    }

    function handleAssign (row, e) {
        business.assignTutor({sign_id: row.id}).then(res => {
            console.log(res);
            getData();
        })
        e.stopPropagation();
    }

    function handleExpand (expanded, record) {
        console.log(expanded, record);
        if (expanded) {
            setExpandRows([...expandRows, record.id]);
        } else {
            setExpandRows(expandRows.filter(i => i !== record.id));
        }
    }

    function cancelAssign (flag) {
        business.cancelAssign({sign_id: assigned_sign.id, is_fail: flag}).then(res => {
            message.success('操作成功');
            getData();
        })
    }

    return (
        <div>

            { assigned_tutor &&  (
                <div className='flex-start-center'>
                    <div>当前指派老师  { assigned_tutor.name }</div>
                    <Button type='link' onClick={() => cancelAssign(0)}>取消指派</Button>
                    { form.status === 'WS'
                        ? <Button type='link' onClick={() => cancelAssign(2)}>取消指派并增加爽约记录</Button>
                        : <Button type='link' onClick={() => cancelAssign(1)}>取消指派并标记为接单失败</Button>
                    }
                </div>
            )
            }


            { expandRows.length < signs.length ?
                <a onClick={() => setExpandRows(signs.map(i => i.id))}>展开全部</a> :
                <a onClick={() => setExpandRows([])}>收起全部</a>}
            <Table
                className='signed-table'
                columns={columns}
                dataSource={signs}
                pagination={false}
                expandable={{
                    expandedRowRender: record => (
                        <>
                            <div style={{ margin: 0 }}>{record.advantage}</div>
                            { record.time_negotiation && <div style={{ margin: 0 }}>{record.time_negotiation}</div>}
                        </>
                    ),
                    expandedRowKeys: expandRows,
                    onExpand: handleExpand,
                }}
                rowClassName={record => {
                    if (record.tutor.id === assigned_tutor_id) {
                        return 'selected table-row'
                    }
                    return 'table-row'
                }}
                rowKey="id"
                onRow={record => {
                    return {
                        // 点击某一行
                        onClick: e => handleClick(record),
                    };
                }}
            />
        </div>
    )
}

export default SignDrawer;