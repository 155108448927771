import { Form, Input, Switch, Button } from 'antd'
import './style.scss';

export default (props) => {
    const { item, onChange } = props;

    const handleChangeReply = (index, res) => {
        if (!res) {
            const replyList = item.reply.filter((_, i) => i !== index);
            console.log(replyList);
            onChange({...item, reply: replyList});
        } else {
            const replyList = item.reply.map((data, i) => i === index ? { reply: res } : data);
            onChange({...item, reply: replyList});
        }
    };

    const addReply = () => {
        const replyList = item.reply;
        replyList.push({reply: ''});
        onChange({...item, reply: replyList});
    }

    return (
        <div className='auto-reply-wrap'>
            <Form>
                <Form.Item label='关键词'>
                    <Input value={item.key} onChange={e => onChange({...item, key: e.target.value})} />
                </Form.Item>
                <Form.Item label='完全匹配'>
                    <Switch checkedChildren="完全匹配" unCheckedChildren="模糊匹配" checked={item.match} onChange={e => onChange({...item, match: e})} />
                </Form.Item>
                <Form.Item label='回复内容'>
                    <Button type='link' onClick={addReply}>新增一条回复</Button>
                    {item.reply.map((reply, index) => (
                        <div className='flex-between-center'>
                            <Input.TextArea className='m-t-10' rows={4} onChange={e => handleChangeReply(index, e.target.value)} value={reply.reply} />
                            <Button type='link' onClick={() => handleChangeReply(index, null)}>删除</Button>
                        </div>
                    ))}
                </Form.Item>
            </Form>
            <Button type='link' onClick={() => onChange(null)}>删除关键词</Button>
        </div>
    )
}