import {Table, message, Tag} from 'antd';
import { useTextSelection, useThrottleFn } from 'ahooks';
import {useEffect} from "react";
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import {useHistory} from "react-router-dom";
import constance from '@/utils/constance';

function getStatus (status, row) {
    const obj = constance.statusOrderList.find(i => i.value === status)
    return (
        <>
            <Tag color={obj.color}>{obj.label}</Tag>
            {status === 'WS' &&  <div>已报{row.signs.length}人</div> }
        </>
    )
}

function getMode (mode) {
    return <Tag color={mode === 1 ? '#F28E1B' : '#347575'}>{mode === 1 ? '上门辅导' : '在线辅导'}</Tag>
}


const columns = [
    { key: 'status', dataIndex: 'status', title: '订单状态', width: 90, fixed: 'left', render: (value, row) => getStatus(value, row)},
    { key: 'grade', dataIndex: 'grade', title: '订单类型', width: 160, fixed: 'left', render: (value, row) => `${value?.name}-${row.subjects.map(i => i.name).join('、')}`},
    { key: 'mode', dataIndex: 'mode', title: '辅导方式', width: 100, fixed: 'left', render: value => value && getMode(value)},
    { key: 'created_at', dataIndex: 'created_at', title: '创建时间', width: 160, render: value => dayjs(value).format('YYYY-MM-DD HH:mm')},
    { key: 'name', dataIndex: 'name', title: '发布者', width: 130, render: (value, row) => <div><div>{value}</div><div>{row.mobile}</div></div>},
    { key: 'district', dataIndex: 'district', title: '所在地区', width: 200, render: (value, row) => value && <><div>{ `${value?.city.name} - ${value?.name}`}</div><div>{row.address}</div></>},
    { key: 'week_times', dataIndex: 'week_times', title: '每周次数', width: 100},
    { key: 'period', dataIndex: 'period', title: '每次时长', width: 100,},
    { key: 'student_gender', dataIndex: 'student_gender', title: '学生性别', width: 100, render: value => value && ['男', '女'][value - 1]},
    { key: 'tutor_gender', dataIndex: 'tutor_gender', title: '教师性别', width: 100, render: value => value && ['男', '女', '男女不限'][value - 1]},
    { key: 'detail', dataIndex: 'detail', title: '学生详情', width: 300,},
    { key: 'tutor_requirement', dataIndex: 'tutor_requirement', title: '教师要求', width: 300,},
]

function OrderListTable ({tutorList, total, filter, updateFilter, unseenList}) {

    const { text } = useTextSelection();
    const history = useHistory();

    const { run: showMessage } = useThrottleFn(() => {
        message.info('已复制到剪贴板').then();
    }, { wait: 3000, trailing: false });

    const unseenIdList = unseenList.map(i => i.order_id)

    useEffect(() => {
        if (text) {
            copy(text);
            showMessage();
        }
    }, [text, showMessage]);

    function handleChangePage (page) {
        updateFilter({ page });
    }

    function handleRow (record) {
        return {
            onClick: event => {
                history.push(`/business/order/${record.id}`);
            }
        }
    }

    return (
        <Table
            columns={columns}
            dataSource={tutorList}
            rowKey="id"
            scroll={{x: 1200}}
            pagination={{
                total: total,
                showTotal:(total) => `共 ${total} 条记录`,
                onChange: handleChangePage,
                defaultPageSize: 10,
                current: filter.page,
            }}
            onRow={handleRow}
            rowClassName={(row) => {
                if (unseenIdList.includes(row.id)) {
                    return 'table-row unseen-row'
                }
                return 'table-row'
            }}
        />
    )
}

export default OrderListTable;