import CityList from '@/pages/pc/modules/admin/city';
import AccountList from '@/pages/pc/modules/admin/account';
import SubjectList from '@/pages/pc/modules/admin/subject';
import WeappManage from '@/pages/pc/modules/admin/weapp';
import NoticeEdit from "@/pages/pc/modules/admin/notice/edit";
import NoticeList from "@/pages/pc/modules/admin/notice/list";
import InviteList from "@/pages/pc/modules/admin/invite";

const adminRouter = [
    {
        path: '/admin/city',
        Component: CityList,
    },
    {
        path: '/admin/account',
        Component: AccountList,
    },
    {
        path: '/admin/subject',
        Component: SubjectList,
    },
    {
        path: '/admin/weapp',
        Component: WeappManage,
    },
    {
        path: '/admin/notice/:id',
        Component: NoticeEdit,
    },
    {
        path: '/admin/notice',
        Component: NoticeList,
    },
    {
        path: '/admin/invite',
        Component: InviteList,
    },
]

export default adminRouter;
