import { useEffect, useMemo, useState } from "react";
import {business} from "@/api";
import { Input, message, Button, Row, Col, Table } from "antd";
import { useAtom } from "jotai";
import Atoms from "@/jotai";
import dayjs from "dayjs";


const columns = [
    { key: 'created_at', dataIndex: 'created_at', title: '时间', render: value => dayjs(value).format('YYYY-MM-DD HH:mm:ss')},
    { key: 'money', dataIndex: 'money', title: '退款', render: (value) => value / 100},
]

export default function TurnoverDetail (props) {
    const { id: turnover_id } = props.match.params;

    const [baseDict, _] = useAtom(Atoms.baseDictAtom);
    const [turnover, setTurnover] = useState({});
    const [refundMoney, setRefundMoney] = useState('');
    const [getMoney, setGetMoney] = useState('');

    const obj = turnover.successful_sign || turnover.trial_bill || {};
    const order = obj?.order || {};
    const tutor = obj?.tutor || obj?.sign?.tutor || {};

    useEffect(() => {
        business.getTurnoverDetail({bill_id: turnover_id}).then(res => {
            const data = res.data.data;
            setTurnover(data);
        });
    }, []);

    const toOrder = () => props.history.push(`/business/order/${order.id}`)
    const toTutor = () => props.history.push(`/business/tutor/${tutor.id}`)
    const handeRefund = () => {
        if (refundMoney * 100 > turnover.money - turnover.refund_money) {
            return message.error('退款金额大于剩余金额');
        }
        const params = {
            id: +turnover_id,
            money: +refundMoney * 100,
            tutor_get_money: +getMoney * 100,
        }
        business.requestRefund(params).then(res => {
            message.success('退款成功')
            setTurnover(res.data.data);
        })
    }

    const back = () => {
        props.history.goBack();
    }

    const handleChangeRefund = e => {
        const refund = e.target.value;
        setRefundMoney(refund);
        if (turnover.type === 2) {
            setGetMoney('0');
        } else {
            if (turnover.type === 1 && tutor.identity.id === 1) {
                const cityObj = baseDict.city.find(i => i.id === turnover.city.id);
                const ratio = cityObj.price_rate;
                if (refund * 100 < turnover.money - turnover.refund_money) {
                    setGetMoney((turnover.money - turnover.refund_money - refund * 100) * ratio / 100 + '');
                } else {
                    setGetMoney('0')
                }
            }
        }
    }

    return (
        <Row>
            <Col span={12}>
                <div>
                    <div className='flex-start-center m-b-10'>
                        <div className='m-r-10'>订单类型</div>
                        <div>{turnover.type === 1 ? '课时费用' : '试教费用'}</div>
                    </div>
                    {order.id &&
                        <div className='flex-start-center'>
                            <div className='m-r-10'>订单编号</div>
                            <div className='m-r-10'>{order.uid}</div>
                            <Button type='link' onClick={toOrder}>查看订单</Button>
                        </div>
                    }

                    {tutor.id &&
                        <div className='flex-start-center'>
                            <div className='m-r-10'>老师姓名</div>
                            <div className='m-r-10'>{tutor.name}</div>
                            <Button type='link' onClick={toTutor}>查看老师</Button>
                        </div>
                    }
                    <div className='flex-start-center m-t-10'>
                        <div className='m-r-10'>订单总金额</div>
                        <div>{turnover.money / 100} 元</div>
                    </div>

                    <div className='flex-start-center m-t-10'>
                        <div className='m-r-10'>已退金额</div>
                        <div>{turnover.refund_money / 100} 元</div>
                    </div>

                    <div className='flex-start-center m-t-10'>
                        <div className='m-r-10'>退款金额</div>
                        <Input value={refundMoney} onChange={handleChangeRefund} className='w200' />
                    </div>

                    <div className='flex-start-center m-t-10'>
                        <div className='m-r-10'>教师原获得</div>
                        <div>{turnover.tutor_wallet?.money / 100} 元</div>
                    </div>

                    <div className='flex-start-center m-t-10'>
                        <div className='m-r-10'>退款后教师获得</div>
                        <Input value={getMoney} onChange={e => setGetMoney(e.target.value)} className='w200' />
                    </div>
                    <div className='m-t-10'>
                        <Button onClick={back} className='m-r-10'>返回</Button>
                        <Button type='primary' onClick={handeRefund}>退款</Button>
                    </div>
                </div>
            </Col>
            <Col span={12}>
                {
                    turnover.refunds && (
                        <Table
                            columns={columns}
                            dataSource={turnover.refunds}
                            rowKey="id"
                            pagination={false}
                        />
                    )
                }
            </Col>
        </Row>

    )
}

