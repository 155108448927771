import { Form, Modal, Input, Switch } from 'antd';
import {useEffect} from "react";

function EditCodeModal (props) {
    const { visible, onOk, onCancel, values } = props;
    const [form] = Form.useForm();

    useEffect(() => {
        if (form) {
            form.setFieldsValue(values);
        }
    }, [values]);

    function handleCancel () {
        form.resetFields();
        onCancel();
    }

    return (
        <Modal
            forceRender={true}
            visible={visible}
            maskClosable={false}
            title="邀请二维码" okText="确定" cancelText="取消"
            onCancel={handleCancel}
            onOk={() => {
                form.validateFields().then(values => {
                    form.resetFields();
                    onOk(values);
                })
            }}
        >
            <Form form={form} initialValues={{ is_super: false }} labelCol={{span: 6}}>
                <Form.Item name="id" noStyle />
                <Form.Item name="name" label="名称" rules={[{required: true, message: '请输入邀请二维码名称'}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="description" label="描述" rules={[{required: true, message: '请输入邀请二维码描述'}]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default EditCodeModal;
