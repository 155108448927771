import {useEffect, useState} from 'react';
import {Menu} from 'antd';
import {Link, useLocation} from "react-router-dom";
import menuConfig from "./menuConfig";
import Atoms from "@/jotai";
import {useAtom} from "jotai";

const {Item, SubMenu} = Menu;

function CustomMenu (props) {
    let location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [userInfo, _] = useAtom(Atoms.userInfoAtom);

    useEffect(() => {
        if (location?.state?.key) {
            setSelectedKeys([location?.state?.key]);
        }
    }, [location]);

    useEffect(() => {
        if (!userInfo.is_super || Object.keys(userInfo).length === 0) {
            setMenuList(menuConfig.filter(i => !i.require_super));
        } else {
            setMenuList(menuConfig);
        }
    }, [userInfo])

    return (
        <Menu
            mode="inline"
            theme="dark"
            selectedKeys={selectedKeys}
            defaultOpenKeys={['business', 'static', 'admin', 'setting']}
        >
            {menuList.map(item => <CustomSubMenu menu={item} key={item.key}/>)}
        </Menu>
    )
}

function CustomSubMenu (props) {
    const { menu, ...others } = props;
    return !!menu.children ? (
        <SubMenu {...menu} {...others}>
            { menu.children.map((item, index) => <CustomSubMenu menu={item} key={item.key}/>) }
        </SubMenu>
    ) : (
        <Item key={menu.key} {...others}>
            <Link to={{pathname: menu.to, state: {key: menu.key}}}>{menu.title}</Link>
        </Item>
    );
}

export default CustomMenu;