import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './styles/common.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { Provider } from 'jotai'

ReactDOM.render(
    // <React.StrictMode>
        <ConfigProvider locale={zhCN}>
            <Provider>
                <React.Suspense fallback={<div>Loading...</div>}>
                    <App/>
                </React.Suspense>
            </Provider>
        </ConfigProvider>
    // </React.StrictMode>,
    ,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
