import {Table, Space, Button} from "antd";
import {PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {useState, useEffect} from "react";
import {admin} from "@/api";
import {useMount} from "ahooks";
import EditAccountModal from './EditAccountModal';

function AccountList () {

    const [accountList, setAccountList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [formValue, setFormValue] =useState({});
    const [total, setTotal] = useState(0);

    useMount(() => {
        getData();
    })

    function getData (page = 1, page_size = 10) {
        admin.getAccountList({ page, page_size }).then(res => {
            setAccountList(res.data.data.data);
            setTotal(res.data.data.total);
        })
    }

    const columns = [
        { key: 'name', dataIndex: 'name', title: '名称', width: 150},
        { key: 'is_super', dataIndex: 'is_super', title: '管理员', width: 100, render: (value, row) => value ? '是' : ''},
        { key: 'mobile', dataIndex: 'mobile', title: '手机', width: 100},
        { key: 'qq', dataIndex: 'qq', title: 'QQ', width: 100},
        { key: 'account', dataIndex: 'user_admin', title: '账号', width: 150, render: (value) => value?.username},
        { key: 'password', dataIndex: 'user_admin', title: '密码', width: 150, render: (value) => value?.password},
        { key: 'city', dataIndex: 'city', title: '管理城市', width: 200, render: (value) => value.map(i => i.name).join(',')},
        { key: 'edit', dataIndex: 'edit', title: '操作', width: 150,
            render: (value, row) => (
                <Space size="middle">
                    <a onClick={() => handleEdit(row)}>编辑</a>
                    <a onClick={() => handleDelete(row)}>删除</a>
                </Space>
            )
        },
    ]

    function handleDelete (row) {
        admin.deleteAccount(row.id).then(res => {
            setAccountList(res.data.data.data);
            setTotal(res.data.data.total);
        })
    }

    function handleEdit (row) {
        if (row) {
            setFormValue({...row, username: row.user_admin?.username, password: row.user_admin?.password});
        } else {
            setFormValue({});
        }
        setVisible(true);
    }

    function handleOk (values) {
        console.log(values, formValue);
        if (formValue.id) {
            // 编辑
            admin.putAccount(formValue.id, values).then(res => {
                setAccountList(res.data.data.data);
                setTotal(res.data.data.total);
            })
        } else {
            // 新建
            admin.createAccount(values).then(res => {
                setAccountList(res.data.data.data);
                setTotal(res.data.data.total);
            })
        }
        setVisible(false);
        setFormValue({});
    }


    return (
        <div>
            <Button className='m-b-10 m-r-10' onClick={() => handleEdit()}><PlusOutlined /></Button>
            <Button className='m-r-10' onClick={() => getData()}><ReloadOutlined /></Button>
            {accountList.length > 0 &&
            <Table
                columns={columns}
                dataSource={accountList}
                rowKey="id"
                pagination={{
                    onChange: (page, pageSize) => getData(page, pageSize),
                    total: total,
                }}
                scroll={{x: 1200}}
            />
            }
            <EditAccountModal visible={visible} onCancel={() => setVisible(false)} onOk={handleOk} values={formValue} />

        </div>
    )
}
export default AccountList;