import axios from '@/api/http';

const http = {
    getTutorList (params) {
        return axios.post('/tutor/list', params);
    },
    getTutor (params) {
        return axios.get('/tutor/detail', { params });
    },
    putTutor (params) {
        return axios.put('/tutor/info', params);
    },
    getOrderList (params) {
        return axios.post('/order/list', params);
    },
    getOrder (params) {
        return axios.get('/order/detail', { params });
    },
    putOrder (params) {
        return axios.put('/order/info', params);
    },
    approvalOrder (params) {
        return axios.post('/order/approval', params);
    },
    createOrder (params) {
        return axios.post('/order/create', params);
    },
    deleteOrder (params) {
        return axios.delete('/order/delete', { params });
    },
    assignTutor (params) {
        return axios.get('/order/sign/assign', {params});
    },
    cancelAssign: params => axios.get('/order/sign/cancel_assign', { params }),
    getTurnoverList: params => axios.post('/bill/list', params),
    getTurnoverDetail: params => axios.get('/bill/detail',{ params }),
    requestRefund: params => axios.post('/bill/refund',params),
    spreadInfo: params => axios.get('/order/spread', {params}),
    getOrderSpreadLog: params => axios.get('/order/spread_log', {params}),
    getSubscribeTutor: params => axios.get('/order/qualified_tutor', {params}),

}

export default http