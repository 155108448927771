import {Table, Tag} from 'antd';
import dayjs from 'dayjs';
import {useHistory} from "react-router-dom";

function getOrderDetail (row, prop) {
    const obj = row.successful_sign || row.trial_bill;
    if (!obj) {
        return '';
    }
    const order = obj.order;
    if (prop !== 'detail') {
        return order[prop];
    }
    return `${order.grade.name} - ${order.subjects.slice(0, 2).map(i => i.name).join()}`;
}

function getTutorDetail (row, prop) {
    const obj = row.successful_sign || row.trial_bill;
    if (!obj) {
        return '';
    }
    const tutor = obj.tutor || obj.sign?.tutor;
    return tutor?.name;
}

function getStatus (value) {
    const color = value ? '#4EDE9D' : '#DE6657'
    return (
        <Tag color={color}>{value ? '成功': '失败'}</Tag>
    )
}
function getType (value) {
    const color = value === 1 ? '#9B6DDE' : '#66ccff'
    return (
        <Tag color={color}>{value === 1 ? '课时费用' : '试教费用'}</Tag>
    )
}

const columns = [
    { key: 'type', dataIndex: 'type', title: '交易类型', render: value => getType(value)},
    { key: 'uid', dataIndex: 'type', title: '订单编号', render: (value, row) => getOrderDetail(row, 'uid')},
    { key: 'detail', dataIndex: 'type', title: '订单详情', render: (value, row) => getOrderDetail(row, 'detail')},
    { key: 'status', dataIndex: 'status', title: '交易状态', render: value => getStatus(value)},
    { key: 'name', dataIndex: 'type', title: '付款家长', render: (value, row) => getOrderDetail(row, 'name')},
    { key: 'tutor', dataIndex: 'type', title: '收款老师', render: (value, row) => getTutorDetail(row)},
    { key: 'money', dataIndex: 'money', title: '交易金额(元)', render: value => value / 100},
    { key: 'refund_money', dataIndex: 'refund_money', title: '已退款金额(元)', render: value => value / 100},
    { key: 'created_at', dataIndex: 'created_at', title: '交易时间', render: value => dayjs(value).format('YYYY-MM-DD hh:mm:ss')},
]

function TurnoverListTable ({turnoverList, total, filter, updateFilter}) {

    const history = useHistory();

    function handleChangePage (page) {
        updateFilter({ page });
    }

    function handleRow (record) {
        return {
            onClick: event => {
                history.push(`/business/turnover/${record.id}`);
            }
        }
    }

    return (
        <Table
            columns={columns}
            dataSource={turnoverList}
            rowKey="id"
            scroll={{x: 1300}}
            pagination={{
                total: total,
                showTotal:(total) => `共 ${total} 条记录`,
                onChange: handleChangePage,
                defaultPageSize: 10,
                current: filter.page,
            }}
            onRow={handleRow}
            rowClassName='table-row'
        />
    )
}

export default TurnoverListTable;