const menuConfig = [
    {
        title: '业务管理',
        key: 'business',
        children: [
            {
                title: '教师列表',
                key: 'tutorList',
                to: '/business/tutor'
            },
            {
                title: '订单列表',
                key: 'orderList',
                to: '/business/order'
            },
            {
                title: '年级管理',
                key: 'gradeList',
                to: '/business/grade',
            },
            {
                title: '交易流水',
                key: 'billList',
                to: '/business/turnover',
            },
            {
                title: '邀请码管理',
                key: 'inviteList',
                to: '/admin/invite',
            },
        ]
    },

    {
        title: '管理员操作',
        key: 'admin',
        require_super: 1,
        children: [
            {
                title: '城市管理',
                key: 'cityList',
                to: '/admin/city',
            },
            {
                title: '科目管理',
                key: 'subjectList',
                to: '/admin/subject',
            },
            {
                title: '管理员账号',
                key: 'accountList',
                to: '/admin/account',
            },
            {
                title: '公众号管理',
                key: 'weapp',
                to: '/admin/weapp'
            },
            {
                title: '文章列表',
                key: 'notice',
                to: '/admin/notice',
                require_super: 1,
            },

        ]
    },

    {
        title: '配置',
        key: 'setting',
        children: [
            {
                title: '文章配置',
                key: 'setting',
                to: '/setting/notice',
            },
        ]
    }

];

export default menuConfig;
