import {Button, Image, Table, Upload, message, Popconfirm} from 'antd';
import {DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import config from '@/config';

function OrderImageDrawer (props) {
    const { images, updateImageList } = props;

    const columns = [
        { key: 'id', dataIndex: 'id', title: 'ID', width: 50},
        { key: 'url', dataIndex: 'url', title: '图片', width: 120, render: value => <Image src={value} />},
        { key: 'operation', dataIndex: 'grade', title: '操作', width: 50, render: (value, row) => (
                <Popconfirm
                    title="确认删除该图片吗"
                    onConfirm={() => handleDeleteImage(row)}
                    okText="确认"
                    cancelText="取消"
                >
                    <DeleteOutlined />
                </Popconfirm>
            )},
    ]

    const uploadProps = {
        name: 'file',
        showUploadList: false,
        action: config.baseURL + '/common/file',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                const newImages = [info.file.response.data, ...images];
                updateImageList(newImages);
                message.success(`${info.file.name}已添加, 记得保存订单资料`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name}上传失败`);
            }
        },
    };

    function handleDeleteImage (row) {

        updateImageList(images.filter(i => i.id !== row.id));
    }

    return (
        <div>
            <Upload {...uploadProps}>
                <Button className='m-b-10' icon={<UploadOutlined />}>新增图片</Button>
            </Upload>
            <Table
                columns={columns}
                dataSource={images}
                rowKey="id"
            />
        </div>
    )
}

export default OrderImageDrawer;