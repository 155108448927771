import {Button, Input, Select, DatePicker} from 'antd';
import Atoms from "@/jotai";
import { ReloadOutlined } from '@ant-design/icons';
import Util from '@/utils';

import './style.scss'
import {useAtom} from "jotai";
import { useDebounceFn } from "ahooks";


function TurnoverFilter ({ updateFilter, getOrderList}) {

    const [cityList, ] = useAtom(Atoms.cityListAtom);
    const dateFormat = 'YYYY-MM-DD';

    const { run: debouncedUpdateFilter } = useDebounceFn(params => {
        updateFilter({...params, page: 1})
    }, { wait: 800 });

    return (
        <div className='flex-start-center filter-item-group'>
            <Input
                placeholder='关键字搜索' className='filter-item w250'
                onChange={e => debouncedUpdateFilter({search: e.target.value})} />

            <Select
                options={Util.getDictSelect(cityList)}
                onChange={e => updateFilter({city: e, page: 1})}
                mode="multiple" maxTagCount={2}
                allowClear className='filter-item w250' placeholder='城市'/>
            <Select
                onChange={e => updateFilter({bill_type: e, page: 1})}
                allowClear className='filter-item w250' placeholder='账单类型'
                mode="multiple">
                <Select.Option value={1}>课时账单</Select.Option>
                <Select.Option value={2}>试教账单</Select.Option>
            </Select>
            <Select
              onChange={e => updateFilter({status: e, page: 1})}
              allowClear className='filter-item w120' placeholder='账单状态'
            >
              <Select.Option value={1}>成功</Select.Option>
              <Select.Option value={0}>等待支付</Select.Option>
              <Select.Option value={-1}>失败</Select.Option>
            </Select>
            <DatePicker.RangePicker
                onChange={(date, dateString) => updateFilter({start_time: dateString[0], end_time: dateString[1], page: 1})}
                format={dateFormat} className='filter-item'
            />
            <Button className='filter-item' onClick={() => getOrderList()}><ReloadOutlined /></Button>
        </div>
    )
}

export default TurnoverFilter;
