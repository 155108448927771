import { Form, Modal, Input, Switch, InputNumber, Select } from 'antd';
import {useEffect, useState} from "react";
import { admin} from '@/api';

function EditCityModal (props) {
    const { visible, onOk, onCancel, values } = props;
    const [form] = Form.useForm();
    const [accountList, setAccountList] = useState([]);

    useEffect(() => {
        if (form && Object.keys(values).length > 0) {
            form.setFieldsValue(values);
        }
    }, [values]);

    useEffect(() => {
        if (visible) {
            admin.getAccountList({page_size: 1000}).then(res => {
                setAccountList(res.data.data.data.map(i => ({ value: i.id, label: i.name })));
            })
        }
    }, [visible]);

    function handleCancel () {
        form.resetFields();
        onCancel();
    }

    return (
        <Modal
            forceRender={true}
            visible={visible}
            width={600}
            maskClosable={false}
            title="城市编辑" okText="确定" cancelText="取消"
            onCancel={handleCancel}
            onOk={() => {
                form.validateFields().then(values => {
                    form.resetFields();
                    onOk(values);
                })
            }}
        >
            <Form
                form={form}
                initialValues={{
                    price_rate: 0.2,
                    try_money: true,
                    award: 0,
                    commission: 0,
                    commission_ratio: 0.1,
                    try_university_ratio: 0.5,
                    try_teacher_ratio: 1,
                    first_order_discount: false,
                    normal_money: true,
                }}
                labelCol={{span: 6}}
            >
                <Form.Item name="name" label="名称" rules={[{required: true, message: '请输入城市名称'}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="expire_at" label="授权到期时间">
                    <Input />
                </Form.Item>
                <Form.Item name="price_rate" label="大学生获利比例" rules={[{required: true, message: '请输入大学生获利比例'}]}>
                    <InputNumber min={0} max={1} step={0.1} />
                </Form.Item>
                <Form.Item label="邀请设置" required>
                    <Form.Item noStyle name='award' rules={[{required: true, message: '请输入每次邀请金额'}]}>
                        <InputNumber min={0} step={1} />
                    </Form.Item>
                    <span className='m-l-5 m-r-5'>元</span>
                    {/*<span className='m-l-5 m-r-5'>元 +</span>*/}
                    {/*<Form.Item noStyle name='commission' rules={[{required: true, message: '请输入抽成次数'}]}>*/}
                    {/*    <InputNumber min={0} step={1} />*/}
                    {/*</Form.Item>*/}
                    {/*<span className='m-l-5 m-r-5'>次 抽成比例</span>*/}
                    {/*<Form.Item noStyle name='commission_ratio' rules={[{required: true, message: '请输入抽成比例'}]}>*/}
                    {/*    <InputNumber min={0} step={1} />*/}
                    {/*</Form.Item>*/}
                </Form.Item>

                <Form.Item label="试教收费比例" required>
                    <span className='m-r-5'>大学生:</span>
                    <Form.Item noStyle name='try_university_ratio' rules={[{required: true, message: '请输入大学生试教比例'}]}>
                        <InputNumber min={0} step={1} />
                    </Form.Item>
                    <span className='m-l-5 m-r-5'>在职教师:</span>
                    <Form.Item noStyle name='try_teacher_ratio' rules={[{required: true, message: '请输入大学生试教比例'}]}>
                        <InputNumber min={0} step={1} />
                    </Form.Item>
                </Form.Item>

                <Form.Item label="试教获利比例" required>
                    <span className='m-r-5'>大学生:</span>
                    <Form.Item noStyle name='try_university_get_ratio' rules={[{required: true, message: '请输入大学生试教比例'}]}>
                        <InputNumber min={0} step={1} />
                    </Form.Item>
                    <span className='m-l-5 m-r-5'>在职教师:</span>
                    <Form.Item noStyle name='try_teacher_get_ratio' rules={[{required: true, message: '请输入大学生试教比例'}]}>
                        <InputNumber min={0} step={1} />
                    </Form.Item>
                </Form.Item>

                <Form.Item label="首单获利比例" required>
                    <span className='m-r-5'>大学生:</span>
                    <Form.Item noStyle name='first_order_discount_university' rules={[{required: true, message: '请输入大学生试教比例'}]}>
                        <InputNumber min={0} step={1} />
                    </Form.Item>
                    <span className='m-l-5 m-r-5'>在职教师:</span>
                    <Form.Item noStyle name='first_order_discount_teacher' rules={[{required: true, message: '请输入大学生试教比例'}]}>
                        <InputNumber min={0} step={1} />
                    </Form.Item>
                </Form.Item>

                <Form.Item name="webhook" label="新订单webhook">
                    <Input />
                </Form.Item>
                <Form.Item name="webhook_sign" label="新报名webhook">
                    <Input />
                </Form.Item>
                <Form.Item name="admin_id" label="管理员" rules={[{required: true, message: '请选择管理员'}]}>
                    <Select options={accountList} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default EditCityModal;