import {Button, Input, Select, Modal} from 'antd';
import Atoms from "@/jotai";
import { ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import Constance from '@/utils/constance';
import { useHistory } from 'react-router-dom';
import Util from '@/utils';

import './style.scss'
import {useAtom} from "jotai";


function OrderFilter ({ filter, updateFilter, getOrderList, unseenList}) {
    
    const [cityList, ] = useAtom(Atoms.cityListAtom);
    const [baseDict, ] = useAtom(Atoms.baseDictAtom);
    const history = useHistory();

    const { run: debouncedUpdateFilter } = useDebounceFn(params => {
        updateFilter({...params, page: 1})
    }, { wait: 800 });

    function createOrder () {
        history.push('/business/order/0')
    }

    return (
        <div className='flex-start-center filter-item-group'>
            <Button className='filter-item' onClick={() => createOrder()}><PlusOutlined /></Button>
            <Select value={filter.status} options={Constance.statusOrderList} onChange={e => updateFilter({status: e && [e], page: 1})} allowClear className='filter-item w120' placeholder='审核状态'/>
            <Select value={filter.city} options={Util.getDictSelect(cityList)} onChange={e => updateFilter({city: e, page: 1})} allowClear className='filter-item w120' placeholder='城市'/>
            <Select value={filter.grades} onChange={e => updateFilter({grades: e, page: 1})} allowClear className='filter-item w270' placeholder='年级' mode="multiple" maxTagCount={1}>
                {baseDict?.grade?.map(gradeGroup => (
                    <Select.OptGroup label={gradeGroup.name} key={gradeGroup.id}>
                        {gradeGroup.grades?.map(grade => (
                            <Select.Option value={grade.id} key={grade.id}>{grade.name}</Select.Option>
                        ))}
                    </Select.OptGroup>
                ))}
            </Select>
            <Select value={filter.subjects} options={Util.getDictSelect(baseDict.subject)} onChange={e => updateFilter({subjects: e, page: 1})} allowClear className='filter-item w250' placeholder='科目' mode="multiple" maxTagCount={1}/>
            <Select value={filter.identities} options={Util.getDictSelect(baseDict.identity)} onChange={e => updateFilter({identities: e && [e], page: 1})} allowClear className='filter-item w120' placeholder='教师类型'/>
            <Select value={filter.mode} onChange={e => updateFilter({mode: e, page: 1})} allowClear className='filter-item w120' placeholder='辅导类型'>
                <Select.Option value={1}>上门辅导</Select.Option>
                <Select.Option value={2}>在线辅导</Select.Option>
            </Select>
            <Select value={filter.unseen} onChange={e => updateFilter({unseen: e, page: 1})} allowClear className='filter-item w120' placeholder='已读情况'>
                <Select.Option value={0}>所有</Select.Option>
                <Select.Option value={1}>只看未读({unseenList.length})</Select.Option>
            </Select>
            <Input defaultValue={filter.search} onChange={e => debouncedUpdateFilter({search: e.target.value})} placeholder='关键字搜索' className='filter-item w250'/>
            <Button className='filter-item' onClick={() => getOrderList()}><ReloadOutlined /></Button>
        </div>
    )
}

export default OrderFilter;