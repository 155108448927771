import {Button, Modal} from 'antd';
import { common } from '@/api';
import {useAtom} from "jotai";
import Atoms from "@/jotai";
import prompt from 'antd-prompt';


export default (props) => {
    const { visible, onCancel, values } = props;
    const [baseDict, setBaseDict] = useAtom(Atoms.baseDictAtom);

    const addDistrict = async () => {
        await prompt({
            title: '请输入城市区名称',
            onOk: name => {
                common.createDistrict({city_id: values.id, name}).then(res => {
                    setBaseDict({city: res.data.data});
                })
            }
        })
    }

    const putDistrict = async (item) => {
        await prompt({
            title: '请输入城市区名称',
            value: item.name,
            onOk: name => {
                common.putDistrict(item.id, {name}).then(res => {
                    setBaseDict({city: res.data.data});
                })
            }
        })
    }

    const deleteDistrict = id => {
        common.deleteDistrict(id).then(res => {
            setBaseDict({city: res.data.data});
        });
    }

    return (
        <Modal
            forceRender={true}
            visible={visible}
            width={300}
            maskClosable={false}
            title="城市区域编辑" okText="确定" cancelText="取消"
            onCancel={onCancel}
            onOk={onCancel}
        >
            <div>
                <Button onClick={addDistrict}>添加区域</Button>
                {
                    values?.districts?.map(item => (
                        <div className='flex-start-center'>
                            <div>{item.name}</div>
                            <Button type='link' onClick={() => putDistrict(item)}>修改</Button>
                            <Button type='link' onClick={() => deleteDistrict(item.id)}>删除</Button>
                        </div>
                    ))
                }
            </div>
        </Modal>
    )
}