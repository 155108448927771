import { Drawer } from "antd";
import {useEffect, useState} from "react";
import OrderImageDrawer from "./OrderImageDrawer";
import SignDrawer from "./SignDrawer";
import SpreadDrawer from "./SpreadDrawer";
import DetailDrawer from "./DetailDrawer";

const titleDict = {
    image: '订单图片',
    sign: '订单报名名单',
    spread: '群发消息',
}

function OrderDrawer (props) {

    const { form, setForm, visible, type, getData, ...others } = props;

    useEffect(() => {
        if (visible) {
            // console.log(form, type);
        }
    }, [visible])

    function handleUpdateImageList (images) {
        setForm({
            ...form,
            images,
        })
    }

    return (
        <Drawer
            title={titleDict[type]}
            placement="right"
            width={800}
            visible={visible}
            {...others}
        >
            {
                type === 'image' && (<OrderImageDrawer images={form?.images} updateImageList={handleUpdateImageList}/>)
            }
            {
                type === 'sign' && (<SignDrawer form={form} signs={form?.signs} assigned_tutor_id={form?.assigned_tutor_id} getData={getData}/>)
            }
            {
                type === 'spread' && (<SpreadDrawer form={form} visible={visible} />)
            }
            {
                type === 'detail' && (<DetailDrawer form={form} visible={visible} />)
            }

        </Drawer>
    )

}

export default OrderDrawer;