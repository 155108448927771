const baseURL = process.env.NODE_ENV === 'development' ?
    // 'http://127.0.0.1:8484/api/v1' :
    'https://v2.shiguangjiajiao.com/api/v1' :
    // 'https://v2-test.shiguangjiajiao.com/api/v1' :
    ('/api/v1');
// const baseURL = 'https://v2.shiguangjiajiao.com/api/v1';

export default {
    baseURL,
}
