import routes from "@/router/router_pc";
import {Redirect, Route, Switch} from "react-router-dom";

function ContentRoute () {
    return (
        <Switch>
            {routes.map((route, i) => (
                <Route
                    key={route.path}
                    path={route.path}
                    exact={!!route?.exact}
                    render={props => (<route.Component {...props} />)}
                />
            ))}
            <Route
                key="default"
                path="/"
                render={() => <Redirect to={{ pathname: '/business/tutor' }} />}
            />
        </Switch>
    )
}

export default ContentRoute