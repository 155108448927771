import {useEffect, useState} from "react";

import OrderListTable from './OrderListTable'
import OrderFilter from './OrderFilter'
import {business} from "@/api";


function OrderList () {
    const [tutorList, setTutorList] = useState([]);
    const [total, setTotal] = useState([]);
    const [unseenList, setUnseenList] = useState([]);
    const orderFilter = localStorage.getItem('orderFilter');
    const [ filter, setFilter ] = useState(orderFilter ? JSON.parse(orderFilter) : {});

    const getOrderList = () => {
        const params = {
            page: 1,
            page_size: 10,
            ...filter
        }
        business.getOrderList(params).then(res => {
            setTutorList(res.data.data.data);
            setTotal(res.data.data.total);
            setUnseenList(res.data.data.unseen_list);
        })
    };

    function updateFilter (params) {
        setFilter(i => ({...i, ...params}));
    }
    useEffect(() => {
        getOrderList();
        localStorage.setItem('orderFilter', JSON.stringify(filter));
    }, [filter])

    return (
        <div className="content-wrapper tutor-list-wrapper">
            <OrderFilter {...{filter, updateFilter, getOrderList, unseenList}}/>
            <OrderListTable {...{tutorList, total, filter, updateFilter, unseenList}}/>
        </div>
    )
}

export default OrderList;