import { Button, Input, Select, Space, Table } from 'antd'
import { useEffect, useState } from "react";
import { business } from "@/api";
import dayjs from "dayjs";
import Util from "@/utils";
import { useAtom } from "jotai";
import Atoms from "@/jotai";

const columns = [
    { key: 'id', dataIndex: 'id', title: 'ID', width: 50},
    { key: 'created_at', dataIndex: 'created_at', title: '时间', render: value => dayjs(value).format('YYYY-MM-DD HH:mm:ss')},
    { key: 'success', dataIndex: 'success', title: '成功次数'},
    { key: 'fail', dataIndex: 'fail', title: '失败次数'},
    { key: 'city', dataIndex: 'city', title: '城市'},
    { key: 'school', dataIndex: 'school', title: '学校关键词'},
    { key: 'black', dataIndex: 'black', title: '黑名单'},
]

export default ({form, visible}) => {
    const [cityList, ] = useAtom(Atoms.cityListAtom);

    const [spreadLog, setSpreadLog] = useState([]);
    const [filter, setFilter] = useState({});
    const [qualifyList, setQualifyList] = useState([]);

    useEffect(() => {
        if (visible) {
            getSpreadLog();
            getSubscribeTutor();
        }
    }, [visible])

    const getSpreadLog = () => {
        business.getOrderSpreadLog({order_id: form.id}).then(res => {
            setSpreadLog(res.data.data);
            console.log(res.data);
        });
    }

    const getSubscribeTutor = () => {
        const params = {
            order_id: form.id,
            ...filter,
            city: filter.city?.join(),
        }
        business.getSubscribeTutor(params).then(res => {
            setQualifyList(res.data.data);
        })
    };

    const send = () => {
        const params = {
            order_id: form.id,
            ...filter,
            city: filter.city?.join(),
        }
        business.spreadInfo(params).then(res => {
            console.log(res);
        })
    }

    return (
        <div>
            <div>
                根据订单 年级, 科目, 辅导老师类型自动筛选. 如有修改, 请先保存订单. 可在此添加 搜索学校 和黑名单 关键词
            </div>
            <div className='flex-start-center'>
                <Space>
                    <Select value={filter.city} options={Util.getDictSelect(cityList)} mode='multiple' maxTagCount={1}
                            onChange={e => setFilter({...filter, city: e})} allowClear className='filter-item w200' placeholder='城市'/>
                    <Input value={filter.search} onChange={e => setFilter({...filter, search: e.target.value})} placeholder='学校搜索' />
                    <Input value={filter.black} onChange={e => setFilter({...filter, black: e.target.value})} placeholder='黑名单' />
                    <Button onClick={getSubscribeTutor}>计算群发人数</Button>
                    <Button onClick={getSpreadLog}>刷新发送历史</Button>
                    <Button onClick={send} type='primary'>发送</Button>
                </Space>
            </div>
            <div>
                <div>符合要求的共有{ qualifyList.length }人</div>
            </div>
            <div className='m-t-20 bold'>发送历史</div>
            <Table
                dataSource={spreadLog}
                columns={columns}
                pagination={false}
            />
        </div>
    )
}