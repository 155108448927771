import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import Login from '@/pages/pc/login';
import Layout from '@/pages/pc/layout';

import Atoms from "@/jotai";
import {useAtom} from "jotai";

function PCMain () {
    const [ token, _ ] = useAtom(Atoms.tokenAtom);
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/login' component={Login} />
                <Route render={props => (
                    token ?
                        (<Layout />) :
                        (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
                )}/>
            </Switch>
        </BrowserRouter>
    )
}

export default PCMain;