import axios from '@/api/http';

const http = {
    login: (params) => axios.post('/login/admin', params),
    getInfo: () => axios.get('/admin/info'),
    getBaseDict: () => axios.get('/dict/dict'),


    putGradeGroup: (data) => axios.put('/dict/grade_group', data),
    putGrade: (data) => axios.put('/dict/grade', data),
    putCityGradePrice: (data) => axios.put('/dict/city_grade_price', data),
    deleteGrade: (params) => axios.delete('/dict/grade', { params }),
    deleteGradeGroup: (params) => axios.delete('/dict/grade_group', { params }),
    createGrade: (data) => axios.post('/dict/grade', data),
    createGradeGroup: (data) => axios.post('/dict/grade_group', data),

    getCityList: (params) => axios.get('/admin/city', { params }),
    putCity: (id, data) => axios.put(`/admin/city/${id}`, data),
    deleteCity: (id) => axios.delete(`/admin/city/${id}`),
    createCity: (data) => axios.post(`/admin/city`, data),
    changeCitySort: (data) => axios.post('/admin/city/order', data),
    createDistrict: data => axios.post('/admin/district', data),
    putDistrict: (id, data) => axios.put(`/admin/district/${id}`, data),
    deleteDistrict: id => axios.delete(`/admin/district/${id}`),

    putSubject: (id, data) => axios.put(`/dict/subject/${id}`, data),
    deleteSubject: (id) => axios.delete(`/dict/subject/${id}`),
    changeSubjectSort: (data) => axios.post(`/dict/subject/order`, data),

}

export default http