import { Form, Modal, Input, Switch } from 'antd';
import {useEffect} from "react";

function EditAccountModal (props) {
    const { visible, onOk, onCancel, values } = props;
    const [form] = Form.useForm();

    useEffect(() => {
        if (form) {
            form.setFieldsValue(values);
        }
    }, [values]);

    function handleCancel () {
        form.resetFields();
        onCancel();
    }

    return (
        <Modal
            forceRender={true}
            visible={visible}
            maskClosable={false}
            title="管理员账号" okText="确定" cancelText="取消"
            onCancel={handleCancel}
            onOk={() => {
                form.validateFields().then(values => {
                    form.resetFields();
                    onOk(values);
                })
            }}
        >
            <Form form={form} initialValues={{ is_super: false }} labelCol={{span: 6}}>
                <Form.Item name="name" label="名称" rules={[{required: true, message: '请输入账号名称'}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="is_super" label="是否为管理员" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item name="mobile" label="手机" rules={[{required: true, message: '请输入手机'}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="qq" label="QQ" rules={[{required: true, message: '请输入QQ'}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="username" label="账号" rules={[{required: true, message: '请输入管理员账号'}]}>
                    <Input placeholder='管理员账号,可以是中文'/>
                </Form.Item>
                <Form.Item name="password" label="密码" rules={[{required: true, message: '请输入管理员密码'}]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default EditAccountModal;