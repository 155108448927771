import {Table, Space, Button} from "antd";
import {PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {useState, useEffect} from "react";
import {admin} from "@/api";
import {useMount} from "ahooks";
import EditCodeModal from './EditCodeModal';

function AccountList () {

    const [inviteList, setInviteList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [formValue, setFormValue] =useState({});
    const [total, setTotal] = useState(0);

    useMount(() => {
        getData();
    })

    function getData (page = 1, page_size = 10) {
        admin.getInviteList({ page, page_size }).then(res => {
            setInviteList(res.data.data.data);
            setTotal(res.data.data.total);
        })
    }

    const columns = [
        { key: 'name', dataIndex: 'name', title: '名称', width: 100},
        { key: 'description', dataIndex: 'description', title: '说明', width: 200},
        { key: 'tutor_count', dataIndex: 'tutor_count', title: '注册用户数', width: 50},
        { key: 'admin', dataIndex: 'admin', title: '创建人', width: 100},
        { key: 'url', dataIndex: 'url', title: '二维码', width: 150, render: (value) => <img src={value} style={{width: 80, height: 80}} />},
        { key: 'edit', dataIndex: 'edit', title: '操作', width: 150,
            render: (value, row) => (
              <Space size="middle">
                  <a onClick={() => handleEdit(row)}>编辑</a>
                  <a onClick={() => handleDelete(row)}>删除</a>
              </Space>
            )
        },
    ]

    function handleDelete (row) {
        admin.deleteInvite(row.id).then(res => {
            setInviteList(res.data.data.data);
            setTotal(res.data.data.total);
        })
    }

    function handleEdit (row) {
        if (row) {
            setFormValue({...row});
        } else {
            setFormValue({});
        }
        setVisible(true);
    }

    function handleOk (values) {
        console.log(values, formValue);
        if (formValue.id) {
            // 编辑
            admin.putInvite(values).then(res => {
                setInviteList(res.data.data.data);
                setTotal(res.data.data.total);
            })
        } else {
            // 新建
            admin.createInvite(values).then(res => {
                setInviteList(res.data.data.data);
                setTotal(res.data.data.total);
            })
        }
        setVisible(false);
        setFormValue({});
    }


    return (
        <div>
            <Button className='m-b-10 m-r-10' onClick={() => handleEdit()}><PlusOutlined /></Button>
            <Button className='m-r-10' onClick={() => getData()}><ReloadOutlined /></Button>
            {inviteList.length > 0 &&
            <Table
                columns={columns}
                dataSource={inviteList}
                rowKey="id"
                pagination={{
                    onChange: (page, pageSize) => getData(page, pageSize),
                    total: total,
                }}
                scroll={{x: 1200}}
            />
            }
            <EditCodeModal visible={visible} onCancel={() => setVisible(false)} onOk={handleOk} values={formValue} />

        </div>
    )
}
export default AccountList;
