import axios from 'axios'
import Util from "@/utils";
import config from '@/config.js';
import {message} from "antd";

const http = axios.create({
    baseURL: config.baseURL,
})

http.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json';
    config.headers['token'] = Util.getToken();
    return config
})

http.interceptors.response.use(response => {
    return response
}, res => {
    console.log(res.response);
    message.error(res.response?.data?.detail);
    return Promise.reject(res.response);
})

export default http