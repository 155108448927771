import { Button, Image, Input, Table } from "antd";
import { admin} from '@/api';
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import dayjs from 'dayjs';

const columns = [
    { key: 'name', dataIndex: 'name', title: '文章标题'},
    { key: 'abstract', dataIndex: 'abstract', title: '文章摘要'},
    { key: 'image', dataIndex: 'image', title: '封面图', render: value => <Image src={value} width={50} preview={false} />},
    { key: 'click', dataIndex: 'click', title: '点击数' },
    { key: 'created_at', dataIndex: 'created_at', title: '创建时间', render: value => dayjs(value).format('YYYY-MM-DD HH:mm')},
]

export default ({ history }) => {

    const [noticeList, setNoticeList] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    useEffect(() => {
        getData();
    }, [search, page]);

    const getData = () => {
        const params = {
            page: page,
            search: search,
        };
        admin.getNoticeList(params).then(res => {
            setNoticeList(res.data.data.data);
            setTotal(res.data.data.total);
        })
    }

    const handleChangePage = page => {
        setPage(page);
    }

    const handleChangeSearch = e => {
        setSearch(e.target.value)
    }

    const createNotice = () => {
        history.push('/admin/notice/0')
    };

    return (
        <div>
            <Button className='m-r-10' onClick={() => createNotice()}><PlusOutlined /></Button>
            <Button className='m-r-10' onClick={() => getData()}><ReloadOutlined /></Button>
            <Input onChange={handleChangeSearch} placeholder='文章标题搜索' className='w250'/>
            <Table
                className='m-t-10'
                columns={columns}
                dataSource={noticeList}
                rowKey="id"
                pagination={{
                    total: total,
                    showTotal:(total) => `共 ${total} 条记录`,
                    onChange: handleChangePage,
                    defaultPageSize: 10,
                    current: page,
                }}
                onRow={ record => ({
                    onClick: event => {
                        history.push(`/admin/notice/${record.id}`);
                    }
                })}
                rowClassName='table-row'
            />
        </div>
    );
};