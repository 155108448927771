import {useCallback, useEffect, useState} from "react";

import TutorListTable from './TutorListTable'
import TutorFilter from './TutorFilter'
import {business} from "@/api";

function TutorList () {
    const [tutorList, setTutorList] = useState([]);
    const [total, setTotal] = useState([]);
    const tutorFilter = localStorage.getItem('tutorFilter');
    const [ filter, setFilter ] = useState(tutorFilter ? JSON.parse(tutorFilter) : {});

    function updateFilter (params) {
        setFilter(i => ({ ...i, ...params }));
    }

    const getTutorList = useCallback(() => {
        const params = {
            page: 1,
            page_size: 10,
            ...filter
        }
        business.getTutorList(params).then(res => {
            setTutorList(res.data.data.data);
            setTotal(res.data.data.total);
        })
    }, [filter]);

    useEffect(() => {
        getTutorList();
        localStorage.setItem('tutorFilter', JSON.stringify(filter));
    }, [getTutorList]);

    return (
        <div className="content-wrapper tutor-list-wrapper">
            <TutorFilter {...{filter, updateFilter, getTutorList}}/>
            <TutorListTable {...{tutorList, total, filter, updateFilter}}/>
        </div>
    )
}

export default TutorList;