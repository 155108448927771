import { useSize } from 'ahooks';
import MobileMain from '@/pages/mobile/main';
import PCMain from '@/pages/pc/main';

function App() {

    const dom = document.querySelector('body');
    const windowSize = useSize(dom);

    return (
        windowSize.width < 100 ? <MobileMain /> : <PCMain />
    )
}

export default App;
