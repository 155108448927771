import { Drawer } from "antd";
import {useEffect, useState} from "react";

function TutorOrderDrawer (props) {

    const { id, visible, tutorId, ...others } = props;

    const [orderList, setOrderList] = useState([]);

    function getTutorOrderList () {
        console.log(123);
        setOrderList([1,2]);
    }

    useEffect(() => {
        if (visible && id && orderList.length === 0) {
            getTutorOrderList()
        }
    }, [id, visible, orderList])

    return (
        <Drawer
            title='教师相关订单详情'
            placement="right"
            width={500}
            visible={visible}
            {...others}
        >
            <div>
                这里是与教师有关的订单详情
            </div>
        </Drawer>
    )

}

export default TutorOrderDrawer;