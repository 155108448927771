import {Table, Space, Modal, Button, message} from "antd";
import {EditOutlined, ExclamationCircleOutlined, PlusOutlined, ReloadOutlined, MenuOutlined} from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {useAtom} from "jotai";
import Atoms from "@/jotai";
import Util from '@/utils';
import prompt from '@/components/prompt';
import {common} from "@/api";
import './style.scss'
import {useEffect, useState} from "react";

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

function SubjectList () {
    const [baseDict, setBaseDict] = useAtom(Atoms.baseDictAtom);
    const [subjectList, setSubjectList] = useState([]);

    useEffect(() => {
        setSubjectList(baseDict.subject);
    }, [baseDict]);

    const columns = [
        { key: 'sort', dataIndex: 'sort', title: '排序', width: 30, className: 'drag-visible', render: () => <DragHandle /> },
        { key: 'name', dataIndex: 'name', title: '名称', width: 150, render: (value, row) => ( value && <Space>{value}<EditOutlined onClick={() => changeName(row)} className='table-hover-icon' /></Space>)},
        { key: 'edit', dataIndex: 'edit', title: '操作', width: 150,
            render: (value, row) => (
                <Space size="middle">
                    <a onClick={() => handleDelete(row)}>删除</a>
                </Space>
            )
        },
    ]

    async function changeName (row) {
        await prompt({
            title: '修改名称',
            value: row.name,
            rules: [{ required: true, message: '名称不可为空' }],
            onOk: name => {
                return common.putSubject(row.id, {name}).then(res => {
                    setBaseDict({subject: res.data.data});
                })
            }
        });
    }

    function handleDelete (row) {
        Modal.confirm({
            title: `是否要删除${row.name}`,
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                return common.deleteSubject(row.id).then(res => {
                    setBaseDict({subject: res.data.data});
                    message.success('删除成功')
                });
            },
        })
    }

    async function handleAdd (row) {
        await prompt({
            title: `新增${row.name}下年级`,
            rules: [{ required: true, message: '名称不可为空' }],
            onOk: name => {
                return business.createGrade({grade_group_id: row.id, name}).then(res => {
                    setBaseDict({grade: res.data.data});
                })
            }
        });
    }

    function refresh () {
        common.getBaseDict().then(res => {
            setBaseDict(res.data.data);
        })
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newSubjectList = arrayMove([].concat(subjectList), oldIndex, newIndex).filter(el => !!el);
            setSubjectList(newSubjectList);
            const change_dict = {};
            newSubjectList.forEach((i, sort) => change_dict[i.id] = sort);
            common.changeSubjectSort({ change_dict }).then(res => {
                setBaseDict({ subject: res.data.data });
            })
        }
    };

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = subjectList.findIndex(x => x.sort === restProps['data-row-key']);
        return <SortableItem className='table-row' index={index} {...restProps} />;
    };

    return (
        <div>
            <Button className='m-r-10 m-b-10' onClick={() => handleAdd()}><PlusOutlined /></Button>
            <Button className='' onClick={() => refresh()}><ReloadOutlined /></Button>
            {subjectList.length > 0 &&
            <Table
                columns={columns}
                dataSource={subjectList}
                rowKey="sort"
                onRow={() => {
                    return {
                        onMouseEnter: Util.handleAddHoverClass, // 鼠标移入行
                        onMouseLeave: Util.handleRemoveHoverClass,
                    };
                }}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                pagination={false}
            />
            }

        </div>
    )
}
export default SubjectList;