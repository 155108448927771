import {Select, Table, Space, Modal, Button, message} from "antd";
import {EditOutlined, ExclamationCircleOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {useAtom} from "jotai";
import Atoms from "@/jotai";
import Util from '@/utils';
import {useState, useEffect} from "react";
import prompt from '@/components/prompt';
import {common} from "@/api";

function GradeList () {
    const [baseDict, setBaseDict] = useAtom(Atoms.baseDictAtom);
    const [cityList, ] = useAtom(Atoms.cityListAtom);
    const [userInfo, _] = useAtom(Atoms.userInfoAtom);

    const [filter, setFilter] = useState({city: cityList.length > 0 ? cityList[0].id : ''})
    const [gradeList, setGradeList] = useState([]);
    const columns = [
        { key: 'name', dataIndex: 'name', title: '名称', width: 150, render: (value, row) => ( value && <Space>{value}{userInfo.is_super && <EditOutlined onClick={() => changeName(row)} className='table-hover-icon' />}</Space>)},
        { key: 'recommend_price', dataIndex: 'recommend_price', title: '基础价格', width: 150, render: (value, row) => ( !row.grades && <Space>{value}{userInfo.is_super && <EditOutlined onClick={() => changeRecommendPrice(row)} className='table-hover-icon' />}</Space>)},
        { key: 'edit_price', dataIndex: 'edit_price', title: '城市独立价格', width: 150, render: (value, row) => (!row.grades && <Space>{value}<EditOutlined onClick={() => changePrice(row)} className='table-hover-icon' /></Space>)},
        { key: 'edit', dataIndex: 'edit', title: '操作', width: 150,
            render: (value, row) => (
                <Space size="middle">
                    <a onClick={() => handleDelete(row)}>删除</a>
                    { row.grades && <a onClick={() => handleAdd(row)}>新增</a>}
                </Space>
            )
        },
    ]

    async function changeName (row) {
        await prompt({
            title: '修改名称',
            value: row.name,
            rules: [{ required: true, message: '名称不可为空' }],
            onOk: name => {
                if (row.grades) {
                    return common.putGradeGroup({id: row.id, name}).then(res => {
                        setBaseDict({grade: res.data.data});
                    })
                } else {
                    return common.putGrade({id: row.id, name}).then(res => {
                        setBaseDict({grade: res.data.data});
                    })
                }
            }
        });
    }

    async function changePrice (row) {
        await prompt({
            title: '修改城市价格',
            value: row.edit_price,
            rules: [{ required: true, message: '价格不可为空' }, { pattern: /^\d+$/g, message: '价格必须是数字'}],
            onOk: price => {
                return common.putCityGradePrice({city_id: filter.city, grade_id: row.id, price}).then(res => {
                    setBaseDict({city: res.data.data});
                })
            }
        });
    }

    async function changeRecommendPrice (row) {
        await prompt({
            title: '修改推荐价格',
            value: row.recommend_price,
            rules: [{ required: true, message: '价格不可为空' }, { pattern: /^\d+$/g, message: '价格必须是数字'}],
            onOk: price => {
                return common.putGrade({id: row.id, recommend_price: price}).then(res => {
                    setBaseDict({grade: res.data.data});
                })
            }
        });
    }

    function handleDelete (row) {
        Modal.confirm({
            title: '是否要删除',
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                if (row.grades) {
                    common.deleteGradeGroup({ grade_group_id: row.id }).then(res => {
                        setBaseDict({grade: res.data.data});
                        message.success('删除成功')
                    })
                } else {
                    common.deleteGrade({ grade_id: row.id }).then(res => {
                        setBaseDict({grade: res.data.data});
                        message.success('删除成功')
                    })
                }
            },
        })
    }

    async function handleAdd (row) {
        await prompt({
            title: `新增${row.name}下年级`,
            rules: [{ required: true, message: '名称不可为空' }],
            onOk: name => {
                return common.createGrade({grade_group_id: row.id, name}).then(res => {
                    setBaseDict({grade: res.data.data});
                })
            }
        });
    }

    async function handleAddGroup () {
        await prompt({
            title: `新增年级分类`,
            rules: [{ required: true, message: '名称不可为空' }],
            onOk: name => {
                return common.createGradeGroup({name}).then(res => {
                    setBaseDict({grade: res.data.data});
                })
            }
        });
    }

    function refresh () {
        common.getBaseDict().then(res => {
            setBaseDict(res.data.data);
        })
    }


    function updateFilter (params) {
        setFilter(i => ({ ...i, ...params }));
    }

    useEffect(() => {
        const gradeList = JSON.parse(JSON.stringify(baseDict.grade));
        const subGrade = gradeList.reduce((res, data) => [...res, ...data.grades], []);
        const city = baseDict.city.find(i => i.id === filter.city);
        city?.grade_city_price.forEach(item => {
            const grade = subGrade.find(i => i.id === item.grade.id);
            grade.edit_price = item.price;
        })
        setGradeList(gradeList);
    }, [filter, baseDict])

    return (
        <div>
            <Button className='m-r-10' onClick={() => handleAddGroup()}><PlusOutlined /></Button>
            <Button className='m-r-10' onClick={() => refresh()}><ReloadOutlined /></Button>
            <Select
                options={Util.getDictSelect(cityList)}
                onChange={e => updateFilter({city: e})}
                value={filter.city} allowClear={false}
                className='filter-item w300 m-b-20' placeholder='城市'
            />

            {gradeList.length > 0 &&
                <Table
                    columns={columns}
                    dataSource={gradeList}
                    childrenColumnName='grades'
                    rowKey="id"
                    defaultExpandAllRows={true}
                    rowClassName='table-row'
                    onRow={() => {
                        return {
                            onMouseEnter: Util.handleAddHoverClass, // 鼠标移入行
                            onMouseLeave: Util.handleRemoveHoverClass,
                        };
                    }}
                />
            }

        </div>
    )
}
export default GradeList;