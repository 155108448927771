const Util = {
    getToken () {
        return this.getLocal('token')
    },

    setToken (token) {
        return this.setLocal('token', token);
    },

    getLocal (name) {
        const res =  localStorage.getItem(name);
        if (res) {
            return JSON.parse(res);
        }
        return null;
    },

    setLocal (name, value) {
        localStorage.setItem(name, JSON.stringify(value));
    },

    getRandomString (n = 10) {
        let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
            a = t.length,
            s = "";
        for (let i = 0; i < n; i++) s += t.charAt(Math.floor(Math.random() * a));
        return s;
    },

    getDictSelect (selectList) {
        return selectList?.map(i => ({ value: i.id, label: i.name }));
    },

    handleAddHoverClass (event) {
        let target = event.target;
        while (target.localName !== 'tr') {
            target = target.parentNode;
        }
        target.classList.add('hover');
    },

    handleRemoveHoverClass (event) {
        let target = event.target;
        while (target.localName !== 'tr') {
            target = target.parentNode;
        }
        target.classList.remove('hover');
    }
}

export default Util;