import TutorList from '@/pages/pc/modules/business/tutor/list';
import OrderList from '@/pages/pc/modules/business/order/list';
import TutorEdit from '@/pages/pc/modules/business/tutor/edit';
import OrderEdit from '@/pages/pc/modules/business/order/edit';
import GradeList from '@/pages/pc/modules/business/grade';
import TurnoverList from '@/pages/pc/modules/business/turnover/list';
import TurnoverDetail from '@/pages/pc/modules/business/turnover/detail';


const businessRouter = [
    {
        path: '/business/tutor/:id',
        Component: TutorEdit,
    },
    {
        path: '/business/tutor',
        Component: TutorList,
    },
    {
        path: '/business/order/:id',
        Component: OrderEdit,
    },
    {
        path: '/business/order',
        Component: OrderList
    },
    {
        path: '/business/grade',
        Component: GradeList,
    },
    {
        path: '/business/turnover/:id',
        Component: TurnoverDetail,
    },
    {
        path: '/business/turnover',
        Component: TurnoverList,
    },
]

export default businessRouter;