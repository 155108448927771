import axios from '@/api/http';

const http = {
    getAccountList: (params) => axios.get('/admin/account', { params }),
    putAccount: (id, data) => axios.put(`/admin/account/${id}`, data),
    deleteAccount: (id) => axios.delete(`/admin/account/${id}`),
    createAccount: (data) => axios.post(`/admin/account`, data),
    createNotice: (params) => axios.post('/admin/notice', params),
    putNotice: (params) => axios.put('/admin/notice', params),
    getNoticeList: (params) => axios.get('/admin/notice/list', { params }),
    getNoticeDetail: (params) => axios.get('/admin/notice', { params }),
    getMsgList: () => axios.get('/wx/msg_list'),
    putMsgList: (data) => axios.put('/wx/msg_list', data),
    createInvite: (data) => axios.post('/invite/code', data),
    getInviteList: () => axios.get('/invite/code'),
    putInvite: (data) => axios.put('/invite/code', data),
    deleteInvite: (id) => axios.delete(`/invite/code/${id}`),
}

export default http
