import {Button, Layout} from 'antd';
import CustomMenu from "./components/CustomMenu";
import ContentRoutes from "./components/ContentRoutes";
import {useHistory} from 'react-router-dom';
import './style.scss';
import Atoms from "@/jotai";
import {useAtom} from "jotai";
import React, {Suspense, useEffect} from 'react';
import {common} from "@/api";

const { Header, Content, Footer, Sider } = Layout;

function MainLayout () {
    const history = useHistory();
    const [token, setToken] = useAtom(Atoms.tokenAtom);
    const [baseDict, setBaseDict] = useAtom(Atoms.baseDictAtom);

    useEffect(() => {
        common.getBaseDict().then(res => {
            setBaseDict(res.data.data);
        })
    }, []);

    function logout () {
        setToken('');
        history.replace('/login');
    }
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider>
                <div className="sidebar-title flex-center">
                    时光家教
                </div>
                <CustomMenu />
            </Sider>
            <Layout style={{height: '100vh'}}>
                <Header style={{ padding: 0 }} className='flex-between-center'>
                    <div className="layout-header" >
                        时光家教后台管理系统
                    </div>
                    <Button onClick={logout} className="m-r-20">登出</Button>
                </Header>
                <Content>
                    <Suspense fallback="Loading...">
                        { Object.keys(baseDict).length > 0 && <ContentRoutes /> }
                    </Suspense>
                </Content>
                <Footer style={{ textAlign: 'center', padding: 10 }}>时光森林科技股份有限公司 ©2018 Created by 冰糖雪梨橙</Footer>
            </Layout>
        </Layout>
    );
}

export default MainLayout;