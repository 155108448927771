const constance = {
    statusTutorList: [
        { value: 'AC', label: '审核完成', color: '#0AF27B' },
        { value: 'WA', label: '等待审核', color: '#F0CE54' },
        { value: 'RE', label: '审核不通过', color: '#F24311' },
    ],

    statusOrderList: [
        { value: 'WA', label: '等待审核', color: '#df8028' },
        { value: 'WS', label: '等待接单', color: '#4428df' },
        { value: 'AC', label: '教师接单', color: '#9B6DDE' },
        { value: 'TR', label: '准备试教', color: '#e989e3' },
        { value: 'TRFI', label: '试教完成', color: '#66ccff' },
        { value: 'FI', label: '订单完成', color: '#4EDE9D' },
        { value: 'HD', label: '已隐藏', color: '#E9EBEF' },
        { value: 'DE', label: '已删除', color: '#DE6657' },
    ],

    genderList: [
        { value: 1, label: '男'},
        { value: 2, label: '女'},
    ],

    tutorGenderList: [
        { value: 1, label: '男'},
        { value: 2, label: '女'},
        { value: 3, label: '男女不限'},
    ],

    modeList: [
        { value: 1, label: '上门辅导'},
        { value: 2, label: '在线辅导'},
    ],

    weekdays: [
        { value: 1, label: '周一'},
        { value: 2, label: '周二'},
        { value: 3, label: '周三'},
        { value: 4, label: '周四'},
        { value: 5, label: '周五'},
        { value: 6, label: '周六'},
        { value: 7, label: '周日'},
    ]


}


export default constance