import {Table, Space, Button, Modal, message} from "antd";
import {ExclamationCircleOutlined, MenuOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {useEffect, useState} from "react";
import {useAtom} from "jotai";
import Atoms from "@/jotai";
import {common} from "@/api";
import EditCityModal from './EditCityModal';
import EditDistrictModal from './EditDistrictModal';
import './style.scss';

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

function CityList () {

    const [baseDict, setBaseDict] = useAtom(Atoms.baseDictAtom);
    const [cityList, setCityList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleDistrict, setVisibleDistrict] = useState(false);
    const [cityValue, setCityValue] =useState({});

    useEffect(() => {
        setCityList(baseDict.city);
        if (cityValue.id) {
            setCityValue(baseDict.city.find(i => i.id === cityValue.id));
        }
    }, [baseDict]);

    const columns = [
        { key: 'sort', dataIndex: 'sort', title: '排序', width: 50, className: 'drag-visible', render: () => <DragHandle /> },
        { key: 'name', dataIndex: 'name', title: '城市', width: 80},
        { key: 'expire_at', dataIndex: 'expire_at', title: '到期时间', width: 80},
        { key: 'price_rate', dataIndex: 'price_rate', title: '大学生获利', width: 90},
        { key: 'admin', dataIndex: 'admin', title: '管理员', width: 120, render: value => value?.name},
        { key: 'award', dataIndex: 'award', title: '邀请奖励', width: 80,
            render: (value, row) => `${value}元`},
        { key: 'try_money', dataIndex: 'try_money', title: '试教收费(大学生/在职教师)', width: 150,
            render: (value, row) => `${row.try_university_ratio}/${row.try_teacher_ratio}`},
        { key: 'try_money_get', dataIndex: 'try_money', title: '试教获利(大学生/在职教师)', width: 150,
            render: (value, row) => `${row.try_university_get_ratio}/${row.try_teacher_get_ratio}`},
        { key: 'first_order_discount', dataIndex: 'first_order_discount', title: '首单获利(大学生/在职教师)', width: 150,
            render: (value, row) => `${row.first_order_discount_university}/${row.first_order_discount_teacher}`},
        // { key: 'normal_money', dataIndex: 'normal_money', title: '后续付费方式', width: 120,
        //     render: (value, row) => value ? `小程序付费` : '线下交易'},
        { key: 'edit', dataIndex: 'edit', title: '操作', width: 150,
            render: (value, row) => (
                <Space size="middle">
                    <a onClick={() => handleEdit(row)}>编辑</a>
                    <a onClick={() => handleSetDistrict(row)}>设置区域</a>
                    <a onClick={() => handleDelete(row)}>删除</a>
                </Space>
            )
        },
    ]

    function handleDelete (row) {
        Modal.confirm({
            title: `是否要删除${row.name}`,
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                common.deleteCity(row.id).then(res => {
                    setBaseDict({city: res.data.data});
                    message.success('删除成功')
                })
            },
        })
    }

    function handleSetDistrict (row) {
        setVisibleDistrict(true);
        setCityValue(row);
    }

    function handleEdit (row) {
        if (row) {
            setCityValue({...row, admin_id: row.admin?.id});
        } else {
            setCityValue({});
        }
        setVisible(true);
    }

    function handleOk (values) {
        console.log(values, cityValue);
        if (cityValue.id) {
            // 编辑
            common.putCity(cityValue.id, values).then(res => {
                setBaseDict({city: res.data.data});
            })
        } else {
            // 新建
            common.createCity(values).then(res => {
                setBaseDict({city: res.data.data});
            })
        }
        setVisible(false);
        setCityValue({});
    }

    function refresh () {
        common.getBaseDict().then(res => {
            setBaseDict(res.data.data);
        })
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newCityList = arrayMove([].concat(cityList), oldIndex, newIndex).filter(el => !!el);
            setCityList(newCityList);
            const change_dict = {};
            newCityList.forEach((i, sort) => change_dict[i.id] = sort);
            common.changeCitySort({ change_dict }).then(res => {
                setBaseDict({ city: res.data.data });
            })
        }
    };

    const DraggableContainer = props => (
        <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = cityList.findIndex(x => x.sort === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };


    return (
        <div>
            <Button className='m-b-10 m-r-10' onClick={() => handleEdit()}><PlusOutlined /></Button>
            <Button className='m-r-10' onClick={() => refresh()}><ReloadOutlined /></Button>
            {cityList.length > 0 &&
            <Table
                columns={columns}
                dataSource={cityList}
                rowKey="sort"
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                pagination={false}
                scroll={{x: 1500}}
            />
            }
            <EditCityModal visible={visible} onCancel={() => setVisible(false)} onOk={handleOk} values={cityValue} />
            <EditDistrictModal visible={visibleDistrict} onCancel={() => setVisibleDistrict(false)} values={cityValue} />

        </div>
    )
}
export default CityList;