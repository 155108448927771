import { Button, Table } from 'antd'
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

export default ({form}) => {

    const history = useHistory();

    const columns = [
        { key: 'created_at', dataIndex: 'created_at', title: '时间', render: value => dayjs(value).format('YYYY-MM-DD HH:mm:ss')},
        { key: 'detail', dataIndex: 'detail', title: '内容'},
        { key: 'wechat_bill', dataIndex: 'wechat_bill', title: '账单', render: value => value ?
                <Button type='link' onClick={() => toTurnover(value.id)}>查看账单</Button>: ''},
        { key: 'name', dataIndex: 'name', title: '操作人'},
    ]

    function toTurnover (turnover_id) {
        history.push(`/business/turnover/${turnover_id}`);
    }

    return (
        <Table
            columns={ columns }
            dataSource={form.detail_logs}
            rowKey="id"
            pagination={false}
        />
    )
}