import { atomWithStorage } from 'jotai/utils';
import { atom } from 'jotai';
import {common} from "@/api";

const tokenAtom = atomWithStorage('token', '');

const userInfoAtom = atom(async (get) => {
    const token = get(tokenAtom);
    if (!token) {
        return {};
    }
    const res = await common.getInfo()
    return res.data.data;
})

const baseDictOriginAtom = atomWithStorage('baseDict', {});

const baseDictAtom = atom(  (get) => get(baseDictOriginAtom),
    (get, set, value) => {
    const dict = get(baseDictOriginAtom);
    set(baseDictOriginAtom, { ...dict, ...value});
})

const cityListAtom = atom(get => {
    const baseDict = get(baseDictOriginAtom);
    const userInfo = get(userInfoAtom);
    const cityList = baseDict.city;
    return cityList.filter(i => i.admin?.id === userInfo.id || userInfo.is_super);
})

export default {
    tokenAtom,
    userInfoAtom,
    baseDictAtom,
    baseDictOriginAtom,
    cityListAtom,
}