import {Card, Image} from 'antd';
import './style.scss'

function TutorHonor ({ items }) {
    return (
        <Card title='奖励荣誉'>
            {items?.map(item => (
                <Card title={item.name} key={item.id} className='m-b-10'>
                    <div>{item.receive_date}</div>
                    <div>{item.detail}</div>
                    <Image.PreviewGroup>
                        {item.images.map(image => (
                            <Image src={image.url} width='100%'/>
                        ))}
                    </Image.PreviewGroup>
                </Card>
            ))}
        </Card>
    )
}

function TutorExperience ({ items }) {
    return (
        <Card title='教学经历'>
            {items?.map(item => (
                <Card title={item.name} key={item.id} className='m-b-10'>
                    <div>{item.start_time} - {item.end_time}</div>
                    <div>{item.detail}</div>
                    <Image.PreviewGroup>
                        {item.images.map(image => (
                            <Image src={image.url} width='100%'/>
                        ))}
                    </Image.PreviewGroup>
                </Card>
            ))}
        </Card>
    )
}

function TutorSchool ({ items }) {
    return (
        <Card title='就读院校'>
            {items?.map(item => (
                <Card title={item.name} key={item.id} className='m-b-10'>
                    <div>{item.start_time} - {item.end_time}</div>
                    <div>{item.degree}  {item.major}</div>
                </Card>
            ))}
        </Card>
    )
}

const res = {
    TutorHonor,
    TutorExperience,
    TutorSchool
}

export default res;
