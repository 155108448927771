import React from 'react';
import {Button, Form, Input, message} from 'antd';
import { common } from '@/api';
import Atoms from "@/jotai";
import {useAtom} from "jotai";

function Login ({location, history}) {
    const [_, setToken] = useAtom(Atoms.tokenAtom);
    function handleLogin (params) {
        common.login(params).then(res => {
            setToken(res.data.data);
            if (location.state?.from?.pathname) {
                history.replace({
                    pathname: location.state?.from?.pathname,
                    state: location.state?.from?.state,
                })
            } else {
                history.replace({
                    pathname: '/business/order',
                    state: { key: 'orderList' },
                })
            }
        }).catch(() => {});

    }

    return (
        <div>
            <Form
                onFinish={handleLogin}
            >
                <Form.Item label='用户名' name='username'>
                    <Input />
                </Form.Item>
                <Form.Item label='密码' name='password'>
                    <Input />
                </Form.Item>
                <Button htmlType="submit">登录</Button>
            </Form>
        </div>
    )
}

export default Login