import { business } from '@/api';
import { useEffect, useState } from "react";
import TurnoverFilter from "./TurnoverFilter";
import TurnoverListTable from "./TurnoverListTable";

export default function TurnoverList () {
    const [turnoverList, setTurnoverList] = useState([]);
    const [total, setTotal] = useState([]);
    const [earn, setEarn] = useState(0);
    const [refund, setRefund] = useState(0);
    const [ filter, setFilter ] = useState({});

    const getOrderList = () => {
        const params = {
            page: 1,
            page_size: 10,
            ...filter
        }
        business.getTurnoverList(params).then(res => {
            setTurnoverList(res.data.data.data);
            setTotal(res.data.data.total);
            setEarn(res.data.data.earn);
            setRefund(res.data.data.refund);
        })
    };

    function updateFilter (params) {
        setFilter(i => ({...i, ...params}));
        console.log({...filter, ...params});
    }
    useEffect(() => {
        getOrderList();
    }, [filter])

    return (
        <div className="content-wrapper turnover-list-wrapper">
            <TurnoverFilter {...{updateFilter, getOrderList}}/>
            <div className="m-b-10">
                总收入: {earn / 100} 退款: {refund / 100}
            </div>
            <TurnoverListTable {...{turnoverList, total, filter, updateFilter}}/>
        </div>
    )
};

