import SettingNotice from '@/pages/pc/modules/setting/notice'

const adminRouter = [

    {
        path: '/setting/notice',
        Component: SettingNotice,
    },
]

export default adminRouter;