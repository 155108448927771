import {
    Form,
    Row,
    Col,
    Input,
    Select,
    Cascader,
    Button,
    message,
    TimePicker,
    Popconfirm,
} from "antd";
import { ArrowLeftOutlined, ReloadOutlined, CopyOutlined, FileTextOutlined } from '@ant-design/icons';
import OrderDrawer from './drawer';
import Clipboard from 'clipboard'

import './style.scss';
import { useCallback, useState } from "react";
import { business } from "@/api";
import { useMount } from 'ahooks';
import Constance from '@/utils/constance';
import Atoms from "@/jotai";
import dayjs from "dayjs";
import { useAtom } from "jotai";


function OrderEdit({ match, history, location }) {
    const [formInstance] = Form.useForm();
    const [baseDict] = useAtom(Atoms.baseDictAtom);
    const [cityList,] = useAtom(Atoms.cityListAtom);

    const [form, setForm] = useState({ images: [] });
    const [visible, setVisible] = useState(false);
    const [drawerType, setDrawerType] = useState('sign');
    const [statusColor, setStatusColor] = useState('');
    const [statusOrderList, setStatusOrderList] = useState(Constance.statusOrderList);

    const getOrderInfo = useCallback(() => {
        business.getOrder({ order_id: match.params.id }).then(res => {
            const data = res.data.data;
            setForm(data);
            console.log(data);
            const itemMapper = {
                grade: [data.grade.grade_group.id, data.grade.id],
                subjects: data.subjects?.map(i => i.id),
                district: [data.district?.city?.id, data.district?.id],
                weekdays: data.weekdays.split('').map(Number),
                start_time: dayjs('2000-01-01 ' + data.start_time),
                end_time: dayjs('2000-01-01 ' + data.end_time),
                // period_time: [dayjs('2022-11-13 ' + data.start_time), dayjs('2022-01-01 ' + data.end_time)],
                tutor_identity: data.tutor_identity.map(i => i.id),
            }
            console.log(itemMapper)
            const origin = Object.keys(data).map(item => {
                return { name: item, value: itemMapper[item] || data[item] };
            });
            formInstance.setFields([...origin, { name: 'parentRemark', value: data.parent?.remark }]);
            const status = res.data.data.status;
            changeStatusColor(status);
            if (status !== 'WA') {
                if (status === 'DE') {
                    setStatusOrderList(Constance.statusOrderList.filter(i => ['DE'].includes(i.value)));
                } else {
                    setStatusOrderList(Constance.statusOrderList.filter(i => !['WA', 'DE'].includes(i.value)));
                }
            } else {
                setStatusOrderList(Constance.statusOrderList.filter(i => ['WA'].includes(i.value)));
            }
        })
    }, [formInstance, match])

    useMount(() => {
        if (match.params.id !== '0') {
            getOrderInfo();
        } else {
            changeStatusColor('WS');
        }
        const clip = new Clipboard('.copyInfo', {
            text: () => copyInfo(),

        })
        clip.on('success', e => {
            message.success({
                content: "复制成功",
            })
        })
    });

    function changeStatusColor(status) {
        setStatusColor(Constance.statusOrderList.find(i => i.value === status)?.color);
        setStatusColor(Constance.statusOrderList.find(i => i.value === status)?.color);
    }

    function handleSubmit(isCopy) {
        const isEdit = match.params.id > 0 && isCopy !== 10;
        const value = formInstance.getFieldsValue(true, ({ touched }) => !(isEdit && !touched));
        console.log(value);
        const data = {
            ...value,
            district_id: value.district?.[1],
            grade_id: value.grade?.[1],
            weekdays: value.weekdays?.join(''),
            start_time: value.start_time ? dayjs(value.start_time).format('HH:mm') : undefined,
            end_time: value.end_time ? dayjs(value.end_time).format('HH:mm') : undefined,
            images: form.images?.map(i => i.id),
            id: match.params.id,
            remark: isCopy === 10 ? (value.remark || '') + `从${match.params.id}复制` : value.remark,
            parentRemark: value.parentRemark,
        }
        if (form.status === 'WA') {
            business.approvalOrder(data).then(res => {
                message.success('审核通过成功').then();
                history.goBack();
            })
        } else if (isEdit) {
            business.putOrder(data).then(res => {
                message.success('修改成功').then();
            })
        } else {
            if (isCopy === 10) {
                data.parent_id = form.parent.id;
            }
            business.createOrder(data).then(res => {
                message.success('新建成功').then();
                history.goBack();
            }).catch()
        }
    }

    function handleDelete() {
        business.deleteOrder({ order_id: match.params.id }).then(() => {
            message.success('删除成功').then();
            history.goBack();
        })
    }

    function handleValueChange(changedValues) {
        console.log(changedValues);
        if (changedValues.status) {
            changeStatusColor(changedValues.status);
        }
    }

    function handleBack() {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.replace('/business/order');
        }
    }

    function handleClickImage() {
        setVisible(true);
        setDrawerType('image');
    }

    function handleOpenSignDrawer() {
        setVisible(true);
        setDrawerType('sign');
    }

    function handleOpenDetailDrawer() {
        setVisible(true);
        setDrawerType('detail');
    }

    function handleSpread() {
        setVisible(true);
        setDrawerType('spread');
    }

    function handleToPreTutor() {
        history.push(`/business/tutor/${form.pre_assigned_tutor_id}`);
    }

    function handleCopy() {
        handleSubmit(10);
    }

    function copyInfo() {
        const values = formInstance.getFieldsValue();
        if (!values.district) {
            return;
        }
        const city = cityList.find(i => i.id === values.district[0]);
        const district = city.districts.find(i => i.id === values.district[1]);
        const grade = baseDict.grade.find(i => i.id === values.grade[0]);
        const subGrade = grade.grades.find(i => i.id === values.grade[1]);
        return `【时光家教】家教信息
辅导模式：${['上门辅导', '在线辅导'][values.mode - 1]}
家教地址：${city.name} - ${district.name} ${values.address}
辅导科目：${values.subjects.map(j => baseDict.subject.find(i => i.id === j).name)} ${values.subject_name || ''}
学员情况：${subGrade.name} ${values.detail}
时间安排：每周${values.week_times}次 ${values.weekdays.sort().map(i => ['', '周一', '周二', '周三', '周四', '周五', '周六', '周日'][i])} ${dayjs(values.start_time).format('HH:mm')} - ${dayjs(values.end_time).format('HH:mm')} 中的任意${values.period}小时 ${values.time_remark}
老师要求：${values.tutor_requirement}
老师性别：${['男', '女', '男女不限'][values.tutor_gender - 1]}
`

        // navigator.clipboard.writeText(copyTest).then(() => {
        //     message.success({
        //         content: "复制成功",
        //     });
        // });
    }

    return (
        <div className='tutor-edit-wrapper'>
            <Form
                form={formInstance}
                initialValues={{
                    'grade': [3, 7],
                    'status': 'WS',
                    'mode': 1,
                    'subjects': [1],
                    'week_times': 2,
                    'period': '2',
                    'weekdays': [],
                    'start_time': dayjs('2000-1-1 18:00:00'),
                    'end_time': dayjs('2000-1-1 20:00:00'),
                    'tutor_identity': [1, 2],
                    'tutor_gender': 3,
                    'images': [],
                }}
                labelCol={{ span: 18 }}
                wrapperCol={{ span: 18 }}
                onFinish={handleSubmit}
                layout="vertical"
                requiredMark={false}
                onValuesChange={handleValueChange}
            >
                <div className='flex-between-center m-b-10'>
                    <div>
                        <Button className='m-r-10' onClick={handleBack}><ArrowLeftOutlined /></Button>
                        <Button className='m-r-10' onClick={getOrderInfo}><ReloadOutlined /></Button>
                        <Button className='m-r-10 copyInfo'><CopyOutlined /></Button>
                        <Button className='m-r-10' onClick={handleOpenDetailDrawer}>操作详情</Button>
                        <Button className='m-r-10' onClick={handleOpenSignDrawer}>报名列表({form?.signs?.length})</Button>
                        {match.params.id &&
                            <>
                                <Button className='m-r-10' onClick={handleSpread}>群发消息</Button>
                                <Button className='m-r-10' onClick={handleCopy}>复制为新订单</Button>
                            </>
                        }


                        {form.status === 'WA' ?
                            <Button type='primary' htmlType="submit" onClick={getOrderInfo}>修改并通过审核</Button>
                            :
                            <Button type='primary' htmlType="submit">提交</Button>
                        }
                    </div>
                    <div>
                        {form.status !== 'DE' &&
                            <Popconfirm
                                title="确定要删除该订单吗"
                                onConfirm={handleDelete}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button danger>删除</Button>
                            </Popconfirm>
                        }
                    </div>
                </div>
                <div className="form-content">
                    <Row gutter={[16, 16]} >
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="教师ID" name="uid">
                                <div>{form.uid}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="创建时间" name="uid">
                                <div>{dayjs(form.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </Form.Item>
                        </Col>
                        {
                            form.pre_assigned_tutor_id && (
                                <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                    <Form.Item label="预选老师" name="uid">
                                        <a onClick={handleToPreTutor}>
                                            详情
                                        </a>
                                    </Form.Item>
                                </Col>
                            )
                        }
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="订单状态" name="status">
                                <Select options={statusOrderList} style={{ color: statusColor }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="辅导类型" name="mode">
                                <Select options={Constance.modeList} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="辅导年级" name='grade'>
                                <Cascader options={baseDict.grade} fieldNames={{ label: 'name', value: 'id', children: 'grades' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="辅导科目" name="subjects" rules={[{ required: true }, { type: 'array' }]}>
                                <Select options={baseDict.subject.map(i => ({ value: i.id, label: i.name }))} mode="multiple" allowClear maxTagCount={4} />
                            </Form.Item>
                        </Col>
                        {form.subject_name &&
                            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                <Form.Item label="科目细分" name="subject_name" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        }
                        <Col xs={24} sm={24} md={24} lg={16} xl={12}>
                            <Form.Item label="学生详情" name="detail" rules={[{ required: true }]}>
                                <Input.TextArea autoSize={true} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="每周次数" name="week_times">
                                <Select options={new Array(7).fill(0).map((i, index) => ({ value: index + 1, label: index + 1 }))} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="每次时长" name="period" rules={[{ required: true }]}>
                                <Input suffix="小时" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="学生性别" name="student_gender" rules={[{ required: true }]}>
                                <Select options={Constance.genderList} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="每周时间" name="weekdays" rules={[{ required: true }]}>
                                <Select options={Constance.weekdays} mode="multiple" maxTagCount={1} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Form.Item label="开始时间" name="start_time">
                                <TimePicker />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Form.Item label="结束时间" name="end_time">
                                <TimePicker />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="时间补充说明" name="time_remark">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="教师身份" name="tutor_identity" rules={[{ required: true }]}>
                                <Select options={baseDict.identity.map(i => ({ value: i.id, label: i.name }))} mode="multiple" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="教师性别" name="tutor_gender">
                                <Select options={Constance.tutorGenderList} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={12}>
                            <Form.Item label="教师要求" name="tutor_requirement" rules={[{ required: true }]}>
                                <Input.TextArea autoSize={true} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="辅导城市" name='district' rules={[{ required: true }]}>
                                <Cascader options={cityList} fieldNames={{ label: 'name', value: 'id', children: 'districts' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="具体地点" name='address'>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="联系人" name='name' rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="联系电话" name='mobile' rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        {
                            form.parent && (
                                <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                    <Form.Item label="联系人备注" name='parentRemark'>
                                        <Input.TextArea autoSize={true} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            )
                        }
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="订单备注" name='remark'>
                                <Input.TextArea autoSize={true} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="图片" name="images">
                                <div className='flex-start-center'>
                                    <div>共{form.images?.length}张</div>
                                    <Button type='link' onClick={handleClickImage}>详情</Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>

            <OrderDrawer
                visible={visible}
                onClose={() => setVisible(false)}
                form={form}
                setForm={setForm}
                type={drawerType}
                getData={getOrderInfo}
            />
        </div>
    )
}

export default OrderEdit;
