import businessRouter from './pc/business'
import adminRouter from './pc/admin'
import settingRouter from './pc/setting'

const routes = [
    ...businessRouter,
    ...adminRouter,
    ...settingRouter,
]

export default routes