import { Col, Row, Select, Button, Modal, message } from "antd";
import Util from "@/utils";
import { useAtom } from "jotai";
import Atoms from "@/jotai";
import { useEffect, useState } from "react";
import { admin, common } from '@/api';
import FetchSelect from "@/components/FetchSelect";

export default ({history}) => {

    const [cityList, ] = useAtom(Atoms.cityListAtom);
    const [baseDict, setBaseDict] = useAtom(Atoms.baseDictAtom);
    const [city, setCity] = useState(0);
    const [noticeObj, setNoticeObj] = useState({});
    const [type, setType] = useState('');
    const [subType, setSubType] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [noticeValue, setNoticeValue] = useState({ value: '' });

    useEffect(() => {
        if (city === 0) {
            setCity(cityList[0].id);
        }
    }, [cityList]);

    useEffect(() => {
        if (city) {
            admin.getNoticeList({city_id: city}).then(res => {
                console.log(res);
                setNoticeObj(res.data.data.data)
                // setBannerList(res.data.data.data);
            });
        }
    }, [city])

    const handleChangeCity = e => {
        setCity(e);
    }

    function changePosition(type, subType, i, j) {
        const res = JSON.parse(JSON.stringify(noticeObj));
        [res[type][subType][i], res[type][subType][j]] = [res[type][subType][j], res[type][subType][i]];
        setNoticeObj(res);
    }

    function deleteNotice(type, subType, index) {
        const res = JSON.parse(JSON.stringify(noticeObj));
        res[type][subType].splice(index, 1);
        setNoticeObj(res);
    }

    async function fetchUserList(search) {
        return admin.getNoticeList({search, is_banner: subType === 'banner'})
            .then((res) =>
                res.data.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                })),
            );
    }

    function save () {
        const params = {
            tutor_banner_notice: noticeObj.tutor.banner.map(i => i.id).join(),
            tutor_common_notice: noticeObj.tutor.common.map(i => i.id).join(),
            parent_banner_notice: noticeObj.parent.banner.map(i => i.id).join(),
            parent_common_notice: noticeObj.parent.common.map(i => i.id).join(),
        }
        common.putCity(city, params).then(res => {
            setBaseDict({city: res.data.data});
            message.success('保存成功');
        })
    }

    function addNotice(type, subType) {
        setType(type);
        setSubType(subType);
        setIsModalVisible(true);
    }

    function handleOk () {
        if (!noticeValue.value) {
            return message.error('请选择一篇文章');
        }
        const res = noticeObj;
        console.log(noticeValue);
        res[type][subType].push({
            id: noticeValue.value,
            name: noticeValue.label,
        })
        setNoticeObj(res);
        setIsModalVisible(false);
    }

    return (
        <div>
            <div className='flex-start-center m-b-30'>
                <Select
                    value={city}
                    options={Util.getDictSelect(cityList)}
                    onChange={handleChangeCity}
                    className='w150 m-r-10' placeholder='城市'
                />
                <Button type='primary' onClick={save}>保存</Button>
            </div>
            {
                Object.keys(noticeObj).map(type => (
                    <div key={type} className='m-b-30'>
                        <div className='f24'>{ type === 'tutor' ? '教师版' : '家长版' }</div>
                        <Row>
                            <Col span={12}>
                                <div className='flex-start-center'>
                                    <div>轮播图文章</div>
                                    <Button type='link' onClick={() => addNotice(type, 'banner')}>添加</Button>
                                </div>
                                { noticeObj[type].banner.map((item, index) => (
                                    <div key={item.id + index + item.name} className='flex-start-center'>
                                        <div className='m-r-10'>{item.name}</div>
                                        <div>
                                            <Button
                                                type='link' disabled={index === 0}
                                                onClick={() => changePosition(type, 'banner', index, index - 1)}
                                            >
                                                上移
                                            </Button>
                                            <Button
                                                type='link' disabled={index === noticeObj[type].banner.length - 1}
                                                onClick={() => changePosition(type, 'banner', index, index + 1)}
                                            >
                                                下移
                                            </Button>
                                            <Button type='link' onClick={() => deleteNotice(type, 'banner', index)}>
                                                删除
                                            </Button>
                                        </div>
                                    </div>
                                )) }
                            </Col>
                            <Col span={12}>
                                <div className='flex-start-center'>
                                    <div>公告文章</div>
                                    <Button type='link' onClick={() => addNotice(type, 'common')}>添加</Button>
                                </div>
                                { noticeObj[type].common.map((item, index) => (
                                    <div key={item.id} className='flex-start-center'>
                                        <div className='m-r-10'>{item.name}</div>
                                        <div>
                                            <Button
                                                type='link' disabled={index === 0}
                                                onClick={() => changePosition(type, 'common', index, index - 1)}
                                            >
                                                上移
                                            </Button>
                                            <Button
                                                type='link' disabled={index === noticeObj[type].common.length - 1}
                                                onClick={() => changePosition(type, 'common', index, index + 1)}
                                            >
                                                下移
                                            </Button>
                                            <Button type='link' onClick={() => deleteNotice(type, 'common', index)}>
                                                删除
                                            </Button>
                                        </div>
                                    </div>
                                )) }
                            </Col>
                        </Row>
                    </div>
                ))
            }

            <Modal title="选择文章" visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)}>
                <FetchSelect
                    value={noticeValue}
                    fetchOptions={fetchUserList}
                    onChange={(newValue) => {
                        setNoticeValue(newValue);
                    }}
                    className='w300'
                    showSearch={true}
                />
            </Modal>

        </div>
    )
}