import {Button, Input, Select} from 'antd';
import Atoms from "@/jotai";
import { ReloadOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import Constance from '@/utils/constance';
import Util from '@/utils';

import './style.scss'
import {useAtom} from "jotai";


function TutorFilter ({ filter, updateFilter, getTutorList}) {
    
    const [baseDict, _] = useAtom(Atoms.baseDictAtom);
    const [cityList, ] = useAtom(Atoms.cityListAtom);

    const { run: debouncedUpdateFilter } = useDebounceFn(params => {
        updateFilter({...params, page: 1})
    }, { wait: 800 });

    return (
        <div className='flex-start-center filter-item-group'>
            <Select value={filter.status} options={Constance.statusTutorList} onChange={e => updateFilter({status: e, page: 1})} allowClear className='filter-item w120' placeholder='审核状态'/>
            <Select value={filter.city} options={Util.getDictSelect(cityList)} onChange={e => updateFilter({city: e, page: 1})} allowClear className='filter-item w120' placeholder='城市'/>
            <Select value={filter.grade} onChange={e => updateFilter({grade: e, page: 1})} allowClear className='filter-item w180' placeholder='年级'>
                {baseDict?.grade?.map(gradeGroup => (
                    <Select.OptGroup label={gradeGroup.name} key={gradeGroup.id}>
                        {gradeGroup.grades?.map(grade => (
                            <Select.Option value={grade.id} key={grade.id}>{grade.name}</Select.Option>
                        ))}
                    </Select.OptGroup>
                ))}
            </Select>
            <Select value={filter.subject} options={Util.getDictSelect(baseDict.subject)} onChange={e => updateFilter({subject: e, page: 1})} allowClear className='filter-item w120' placeholder='科目'/>
            <Select value={filter.identity} options={Util.getDictSelect(baseDict.identity)} onChange={e => updateFilter({identity: e, page: 1})} allowClear className='filter-item w120' placeholder='教师类型'/>
            <Input defaultValue={filter.school} onChange={e => debouncedUpdateFilter({school: e.target.value})} placeholder='学校搜索' className='filter-item w250'/>
            <Input defaultValue={filter.search} onChange={e => debouncedUpdateFilter({search: e.target.value})} placeholder='关键字搜索' className='filter-item w250'/>
            <Button className='filter-item' onClick={() => getTutorList()}><ReloadOutlined /></Button>
        </div>
    )
}

export default TutorFilter;