import {Form, Row, Col, Input, Select, Cascader, DatePicker, Button, Tag, Space, message} from "antd";
import { ArrowLeftOutlined, ReloadOutlined, CheckOutlined, FileTextOutlined, CopyOutlined } from '@ant-design/icons';
import EditSubInfo from "./EditSubInfo";
import TutorOrderDrawer from './TutorOrderDrawer';

import './style.scss';
import {useCallback, useState} from "react";
import {business} from "@/api";
import Constance from '@/utils/constance';
import Atoms from "@/jotai";
import dayjs from "dayjs";
import { useMount } from 'ahooks';
import {useAtom} from "jotai";



function TutorEdit ({match, history}) {
    const [formInstance] = Form.useForm();
    const [baseDict] = useAtom(Atoms.baseDictAtom);

    const [form, setForm] = useState({});
    const [visible, setVisible] = useState(false);
    const [statusColor, setStatusColor] = useState('');

    const getTutorInfo = useCallback(() => {
        business.getTutor({ tutor_id: match.params.id }).then(res => {
            const data = res.data.data;
            setForm(data);
            const origin = Object.keys(data).map(item => {
                if (item === 'district') {
                    return { name: item, value: [data.district?.city?.id, data.district?.id]}
                }
                if (item === 'birthday') {
                    return { name: item, value: dayjs(data[item])}
                }
                if (item === 'identity') {
                    return { name: 'identity_id', value: data[item]?.id }
                }
                if (item === 'subjects') {
                    return { name: item, value: data[item]?.map( i=> i.id ) }
                }
                return { name: item, value: data[item]};
            });
            formInstance.setFields(origin);
            changeStatusColor(res.data.data.status)
        })
    }, [formInstance, match])

    useMount(() => {
        getTutorInfo();
    })

    function changeStatusColor (status) {
        setStatusColor(Constance.statusTutorList.find(i => i.value === status)?.color);
    }

    function handleSubmit () {
        const value = formInstance.getFieldsValue(true, ({touched}) => touched);
        console.log(value);
        const data = {
            ...value,
            birthday: value.birthday && dayjs(value.birthday).format('YYYY-MM'),
            district_id: value.district?.[1],
            id: match.params.id,
        }
        business.putTutor(data).then(res => {
            message.success('修改成功')
        })
    }

    function handleValueChange (changedValues) {
        if (changedValues.status) {
            changeStatusColor(changedValues.status);
        }
    }

    function handleBack () {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.replace('/business/tutor');
        }
    }

    function copyInfo () {
        const values = formInstance.getFieldsValue();
        console.log(values)
        const schoolName = form.schools.map(i => `${i.name} ${i.degree} ${i.major} ${i.start_time} ${i.end_time === '至今' ? '至今' : '- ' + i.end_time}`).join('\n')
        const honorName = form.honors.map(i => `${i.reveive_date || ''} ${i.name || ''} ${i.detail || ''}`).join('\n')
        const copyTest = `${values.name.slice(0, 1)}老师 ${['男', '女'][values.gender - 1]}
【就读院校】
${schoolName}
【教学特点】
${values.advantage || ''}
【奖励荣誉】
${honorName}
【教学经历】
${form.experience.map(i => (i.name || '') + ' ' + (i.detail || '')).join('\n')}
`
        console.log(copyTest)
        navigator.clipboard.writeText(copyTest).then(() => {
            message.success({
                content: "复制成功",
            });
        });
    }

    return (
        <div className='tutor-edit-wrapper'>
            <Form
                form={formInstance}
                labelCol={{ span: 18 }}
                wrapperCol={{ span: 18 }}
                onFinish={handleSubmit}
                layout="vertical"
                requiredMark={false}
                onValuesChange={handleValueChange}
            >
                <div className='m-b-10' >
                    <Button className='m-r-10' onClick={handleBack}><ArrowLeftOutlined /></Button>
                    <Button className='m-r-10' onClick={copyInfo}><CopyOutlined /></Button>
                    <Button className='m-r-10' onClick={() => setVisible(i => !i)}><FileTextOutlined /></Button>
                    <Button className='m-r-10' onClick={getTutorInfo}><ReloadOutlined /></Button>
                    <Button htmlType="submit"><CheckOutlined /></Button>
                </div>
                <div className="form-content">
                    <Row gutter={[16, 16]} >
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="教师ID" name="uid">
                                <div>{form.uid}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="创建时间" name="uid">
                                <div>{dayjs(form.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="邀请码" name="uid">
                                <div>{form.scene ? form.scene.name : '无'}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="教师ID" name="uid">
                                <div>{form.uid}</div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="姓名" name="name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="审核状态" name="status">
                                <Select options={Constance.statusTutorList} style={{color: statusColor}} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="性别" name="gender">
                                <Select options={Constance.genderList} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="城市" name='district'>
                                <Cascader options={baseDict.city} fieldNames={{ label: 'name', value: 'id', children: 'districts' }}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="出发地址" name='address'>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="手机" name='mobile'>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="出生年月" name='birthday'>
                                <DatePicker format={'YYYY-MM'} picker="month" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="教师身份" name='identity_id'>
                                <Select options={baseDict.identity.map(i => ({ value: i.id, label: i.name }))} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item label="辅导科目" name="subjects">
                                <Select options={baseDict.subject.map(i => ({ value: i.id, label: i.name }))} mode="multiple" allowClear maxTagCount={4}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={12}>
                            <Form.Item label="教学优势" name="advantage">
                                <Input.TextArea autoSize={true} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={12}>
                            <Form.Item label="教师备注" name="remark">
                                <Input.TextArea autoSize={true} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={12}>
                            <Form.Item label="辅导年级">
                                <Space wrap>
                                    {form.grades?.map(item => (
                                        <Tag key={item.grade.id} className='grade-tag'>{item.grade.name} {item.price}</Tag>
                                    ))}
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
            <Row gutter={[16, 16]} className='m-t-10'>
                <Col span={8}><EditSubInfo.TutorSchool items={form.schools}/></Col>
                <Col span={8}><EditSubInfo.TutorHonor items={form.honors}/></Col>
                <Col span={8}><EditSubInfo.TutorExperience items={form.experience}/></Col>
            </Row>

            <TutorOrderDrawer
                visible={visible}
                onClose={() => setVisible(false)}
                tutorId={match.params.id}
            />
        </div>
    )
}

export default TutorEdit;
