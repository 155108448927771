import { admin } from '@/api';
import {useEffect, useState} from "react";
import { Tabs, Input, Button, message } from 'antd';
import AutoReply from './AutoReply';

export default (props) => {

    const [msgList, setMsgList] = useState({});

    useEffect(() => {
        admin.getMsgList().then(res => {
            console.log(res)
            setMsgList(res.data);
        })
    }, []);

    const save = () => {
        admin.putMsgList({data: JSON.stringify(msgList)}).then(() => {
            message.success('保存成功');
        })
    }

    const handleChange = (index, res) => {
        if (!res) {
            setMsgList({...msgList, auto: msgList.auto.filter((_, i) => i !== index)});
        } else {
            setMsgList({...msgList, auto: msgList.auto.map((item, i) => i === index ? res : item)});
        }
    }

    const addAuto = () => {
        const autoList = msgList.auto;
        autoList.unshift({
            key: '',
            match: true,
            reply: [{reply: ''}],
        });
        setMsgList({...msgList, auto: autoList});
    }

    return (
        <div>
            <Tabs defaultActiveKey="3">
                <Tabs.TabPane tab="关键词回复" key="3" style={{height: 'calc(100vh - 250px)', overflow: 'auto'}}>
                    <Button type='link' onClick={addAuto}>新增关键词</Button>
                    {
                        msgList.auto?.map((item, index) => <AutoReply key={index} item={item} onChange={res => handleChange(index, res)} />)
                    }
                </Tabs.TabPane>
                <Tabs.TabPane tab="关注回复" key="1">
                    <Input.TextArea value={msgList?.subscribe} rows={30}
                                    onChange={e => setMsgList({...msgList, subscribe: e.target.value})}  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="普通消息回复" key="2">
                    <Input.TextArea value={msgList?.reply} rows={30}
                                    onChange={e => setMsgList({...msgList, reply: e.target.value})}  />
                </Tabs.TabPane>
            </Tabs>
            <Button type='primary' onClick={save} className='m-t-10'>保存</Button>
        </div>
    )
}