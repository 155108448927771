import { admin } from '@/api';
import { useEffect, useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react';
import config from '@/config';
import { Form, Input, Button, Upload, message, Image, Row, Col } from 'antd';
import { UploadOutlined } from "@ant-design/icons";

const UploadItem = (props) => {
    const uploadProps = {
        name: 'file',
        showUploadList: false,
        action: config.baseURL + '/common/file',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                props.onChange(info.file.response.data.url);
                props.setImage(info.file.response.data.url);
                message.success(`${info.file.name}封面图上传成功`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name}上传失败`);
            }
        },
    };
    return (
        <Upload {...uploadProps}>
            <Button className='m-b-10' icon={<UploadOutlined />}>上传封面图</Button>
        </Upload>
    )
}

export default ({history, match}) => {

    const [initValue, setInitValue] = useState('');
    const [image, setImage] = useState('');
    const [formInstance] = Form.useForm();
    const editorRef = useRef(null);

    useEffect(() => {
         if (match.params.id !== '0') {
             admin.getNoticeDetail({notice_id: +match.params.id}).then(res => {
                 console.log(res);
                 const data = res.data.data;
                 formInstance.setFields([
                     { name: 'name', value: data.name},
                     { name: 'abstract', value: data.abstract},
                     { name: 'image', value: data.image},
                 ])
                 setImage(data.image);
                 setInitValue(data.content);
             })
         }
    }, []);

    const back = () => {
        history.replace('/admin/notice');
    }

    const handleSubmit = (res) => {
        const data = {
            ...res,
            content: editorRef.current.getContent(),
        }
        if (match.params.id !== '0') {
            admin.putNotice({ ...data, id: +match.params.id }).then(res => {
                message.success('修改成功');
                back();
            })
        } else {
            admin.createNotice(data).then(res => {
                message.success('新建成功');
                back();
            })
        }
    }

    return (
        <div>
            <Form
                form={formInstance}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                onFinish={handleSubmit}
            >
                <Row>
                    <Col span={12}>
                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                            // apiKey='98q4e611kbjmaxe0hcax7jlbqqoh19reyewbz7df87qvq6h1'
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={initValue}
                            init={{
                                height: 500,
                                language: 'zh_CN',
                                fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 24px 28px 32px 36px 40px 48px 56px 64px 72px",
                                menubar: 'file edit view insert format tools table tc help',
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount indent2em'
                                ],
                                toolbar: 'undo redo | fontsizeselect | ' +
                                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist indent2em | image |' +
                                    'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                images_upload_url: `${config.baseURL}/common/admin_file`,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item label='标题' name='name' rules={[{required: true}]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="简介摘要" name="abstract" rules={[{required: true}]}>
                            <Input.TextArea autoSize={{ minRows: 3 }} style={{width: '100%'}} />
                        </Form.Item>
                        <Form.Item label='封面图'>
                            { !image ? (
                                <Form.Item  name='image'>
                                    <UploadItem setImage={setImage} />
                                </Form.Item>
                            ) : (
                                <div>
                                    <Image width={200} src={ image } />
                                    <Form.Item  name='image'>
                                        <UploadItem setImage={setImage} />
                                    </Form.Item>
                                </div>
                            ) }
                        </Form.Item>

                    </Col>
                </Row>
                <div className='m-t-10'>
                    <Button type='' onClick={back} className='m-r-10'>返回</Button>
                    <Button type='primary' htmlType="submit" className=''>提交</Button>
                </div>
            </Form>
        </div>
    );
}