import {Table, message, Tag} from 'antd';
import { useTextSelection, useThrottleFn } from 'ahooks';
import {useEffect} from "react";
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import {useHistory} from "react-router-dom";
import constance from '@/utils/constance';

function getSchool (schoolList) {
    return (
        <>
            {schoolList.slice(0, 2).map(school => (
                <div key={school.id}>{`${school.name} ${school.degree} ${school.major}`}</div>
            ))}
        </>
    )
}

function getStatus (status) {
    const obj = constance.statusTutorList.find(i => i.value === status)
    return (
        <Tag color={obj.color}>{obj.label}</Tag>
    )
}

const columns = [
    { key: 'status', dataIndex: 'status', title: '审核状态', width: 120, fixed: 'left', render: value => getStatus(value)},
    { key: 'name', dataIndex: 'name', title: '姓名', width: 100, fixed: 'left', },
    { key: 'mobile', dataIndex: 'mobile', title: '手机', width: 150,},
    { key: 'gender', dataIndex: 'gender', title: '性别', width: 80, render: value => value === 1 ? '男': '女'},
    { key: 'birthday', dataIndex: 'birthday', title: '年龄', width: 80, render: value => value && dayjs().diff(dayjs(value), 'year')},
    { key: 'identity', dataIndex: 'identity', title: '教师类别', width: 100, render: value => value?.name},
    { key: 'schools', dataIndex: 'schools', title: '就读院校', width: 300, render: value => getSchool(value), ellipsis: true,},
    { key: 'advantage', dataIndex: 'advantage', title: '教学特点', width: 500, ellipsis: true,},
]

function TutorListTable ({tutorList, total, filter, updateFilter}) {

    const { text } = useTextSelection();
    const history = useHistory();

    const { run: showMessage } = useThrottleFn(() => {
        message.info('已复制到剪贴板').then();
    }, { wait: 3000, trailing: false });

    useEffect(() => {
        if (text) {
            copy(text);
            showMessage();
        }
    }, [text, showMessage]);

    function handleChangePage (page) {
        updateFilter({ page });
    }

    function handleRow (record) {
        return {
            onClick: event => {
                history.push(`/business/tutor/${record.id}`);
            }
        }
    }

    return (
        <Table
            columns={columns}
            dataSource={tutorList}
            rowKey="id"
            scroll={{x: 1200}}
            pagination={{
                total: total,
                showTotal:(total) => `共 ${total} 条记录`,
                onChange: handleChangePage,
                defaultPageSize: 10,
                current: filter.page,
            }}
            onRow={handleRow}
            rowClassName='table-row'
        />
    )
}

export default TutorListTable;